import '@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-enterprise/all-modules/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { LicenseManager } from 'ag-grid-enterprise';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import Package from '../package.json';
//import './index.css';
import App from './App';
import rootReducer from './reducers/index';
import * as serviceWorker from './serviceWorker';

window.user = {};

// Log Application and Version to Console
console.log(`Application: ${Package.name}`);
console.log(`Version: ${Package.version}`);

window.user = {};

// Set AG Grid License Key
LicenseManager.setLicenseKey(
  'CompanyName=Alliance Safety Council,LicensedApplication=Pyvot Track,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-007286,ExpiryDate=10_March_2021_[v2]_MTYxNTMzNDQwMDAwMA==f4537bab8d8473bd57010d4e908ade27'
);

const composeMiddleware = () => {
  let middleware = [applyMiddleware(thunk)];

  if (window.__REDUX_DEVTOOLS_EXTENSION__) {
    middleware.push(window.__REDUX_DEVTOOLS_EXTENSION__());
  }

  return compose(...middleware);
};

const store = createStore(rootReducer, composeMiddleware());

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
