import MainCard from "@alliancesafetycouncil/asc-card";
import PageTitle from "@alliancesafetycouncil/asc-page-title";
import React from "react";
import { Breadcrumb, BreadcrumbItem, Button, Card, Col, Row } from "reactstrap";

const DetailsTab = ({ profile }) => (
  <div className="flex flex-1 flex-col">
    <Breadcrumb tag="nav" listTag="div">
      <BreadcrumbItem tag="a" active>
        My Profile
      </BreadcrumbItem>
    </Breadcrumb>
    <PageTitle icon="profile">My Profile</PageTitle>
    <MainCard type="main">
      <br />
      <strong>My Information</strong>
      <Card>
        <Row form>
          <Col md={6}>
            <div>
              {profile.name.first} {profile.name.last}
              <br />
            </div>
            <div>
              {profile.roles}
              <br />
            </div>
            <div>
              <a href={`tel:${profile.phone}`}>{profile.phone}</a>
              <br />
            </div>
            <a href={`mailto:${profile.email}`}>{profile.email}</a>
          </Col>
          <Col md={6} style={{ textAlign: "right" }}>
            <Button color="primary" tag="a" href="#/profile/edit-profile">
              Edit
            </Button>
          </Col>
        </Row>
      </Card>
      <br />
      <strong>Password</strong>
      <Card>
        <Row form>
          <Col md={6}>
            <strong>Current Password:</strong>
            <p>*******</p>
          </Col>
          <Col md={6} style={{ textAlign: "right" }}>
            <Button color="primary" tag="a" href="#/profile/change-password">
              Change
            </Button>
          </Col>
        </Row>
      </Card>
    </MainCard>
  </div>
);

export default DetailsTab;
