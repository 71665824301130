import MainCard from "@alliancesafetycouncil/asc-card";
import React, { Component } from "react";
import { Link, Route, Switch } from "react-router-dom";
import { Button } from "reactstrap";

class TabDisplay extends Component {
  constructor(props) {
    super(props);

    this.state = {
      active: props.tabs.find((tab) => tab.default).name,
    };
  }

  handleTab = (tab) => {
    this.setState({
      active: tab,
    });
  };

  isSelected = (active, tab) => {
    return active === tab ? "bg-white rounded-t-xl text-gray-900 hover:no-underline shadow-md" : "";
  };

  render() {
    const { tabs, match, edit } = this.props;

    const active = window.location.href.split("/").reverse()[0].toLowerCase();
    const isDetails = active === "details";

    const routes = [...tabs].reverse();

    return (
      <div className="flex flex-1 flex-col">
        <div className="flex ml-5">
          {tabs.map((tab) => {
            return (
              !tab.disabled && (
                <Link
                  to={`${match.url}${tab.path}`}
                  onClick={() => this.handleTab(tab.name)}
                  key={`tab-nav-${tab.name}`}
                  className={`px-5 py-4 text-gray-600 hover:no-underline ${this.isSelected(`/${active}`, tab.path)}`}
                >
                  {tab.name}
                </Link>
              )
            );
          })}
        </div>
        <MainCard type="main">
          <div className={!isDetails ? "tab-nav" : ""}>
            {isDetails && edit && (
              <div className="mb-5 text-right">
                <Button color="primary" tag="a" href={edit.path}>
                  {edit.label}
                </Button>
              </div>
            )}
          </div>
          <div className="flex flex-1 flex-col">
            <Switch>
              {
                /**
                 * reverse here so that Sites can render as the default* route,
                 * and maintain the tab menu order.
                 *
                 *      *default, the way it's setup the default route has to be last
                 */
                routes.map((route, idx) => {
                  return route.component ? (
                    <Route
                      key={idx}
                      path={route.default ? "" : `${match.path}${route.path}`}
                      exact={route.exact}
                      name={route.name}
                      render={(props) => <route.component {...this.props} showEdit={route.showEdit} />}
                    />
                  ) : null;
                })
              }
            </Switch>
          </div>
        </MainCard>
      </div>
    );
  }
}

export default TabDisplay;
