import ASCButton from '@alliancesafetycouncil/asc-button';
import MainCard from '@alliancesafetycouncil/asc-card';
import React from 'react';
import { connect } from 'react-redux';
import { Button, Col, FormGroup, Label, Row } from 'reactstrap';
import { Field, Form, formValueSelector, reduxForm } from 'redux-form';
import SelectInput from '../../../components/FormContainer/SelectInput';
import TextInput from '../../../components/FormContainer/TextInput';
import { oneOf, required, validEmail, validPassword, validPhone } from '../../../library/Validation';

let AddUsersForm = ({
  handleNetworkId,
  handleSubmit,
  pristine,
  submitting,
  companies = ['Select Company'],
  role,
  roles,
  sites,
  getSites,
  handlePasswordVisibility,
  showPassword,
  history,
  siteBusy,
}) => {
  return (
    <Form onSubmit={handleSubmit}>
      <MainCard type="main">
        <Row form>
          <Col lg={6}>
            <FormGroup>
              <Field
                type="text"
                name="name.first"
                id="firstName"
                label="First Name"
                component={TextInput}
                placeholder=""
                className="form-control"
                validate={[required]}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col lg={6}>
            <FormGroup>
              <Field
                type="text"
                name="name.last"
                id="lastName"
                label="Last Name"
                component={TextInput}
                placeholder=""
                className="form-control"
                validate={[required]}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col lg={6}>
            <FormGroup>
              <Field
                id="status"
                name="status"
                className="form-control"
                label="Status"
                validate={[oneOf(['active', 'inactive', 'unverified'])]}
                component={SelectInput}
              >
                <option>Please Select</option>
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
                <option value="unverified">Unverified</option>
              </Field>
            </FormGroup>
          </Col>
        </Row>
        <Row form className="user-row-two">
          <Col md={6} className="add-col user-col-two">
            <Field
              id="company"
              name="company"
              className="form-control"
              label="Company"
              component={SelectInput}
              onChange={(e) => {
                getSites(e.target.value);
                handleNetworkId(e.target.value);
              }}
            >
              <option value="">None</option>
              {companies
                .filter((company) => company.status === 'active')
                .sort((a, b) => (a.name > b.name ? 1 : -1))
                .map((company) => {
                  return (
                    <option key={company.network.id} value={company.network.id}>
                      {company.name}
                    </option>
                  );
                })}
            </Field>
          </Col>
          <Col md={6} className="add-col">
            <Field
              id="role"
              name="role"
              className="form-control"
              label="Role"
              component={SelectInput}
              validate={[oneOf(roles.map((role) => role.value))]}
            >
              <option>Select Role</option>
              {roles &&
                roles.map((role) => {
                  return (
                    <option key={role.value} value={role.value}>
                      {role.label}
                    </option>
                  );
                })}
            </Field>
          </Col>
        </Row>
        {(role === 'siteadmin' || role === 'gateguard') && (
          <Row form>
            <Col lg={6}>
              <FormGroup>
                <Field
                  id="site"
                  name="site"
                  className="form-control"
                  label="Site"
                  component={SelectInput}
                  validate={[required]}
                  disabled={siteBusy}
                >
                  <option value={undefined}>{siteBusy ? 'Loading...' : 'Select Site'}</option>
                  {sites &&
                    sites.map((site) => {
                      return (
                        <option key={site.id} value={site.id}>
                          {site.name}
                        </option>
                      );
                    })}
                </Field>
              </FormGroup>
            </Col>
          </Row>
        )}
        <br></br>
        <Row form className="user-row-three">
          <Col md={6} className="add-col user-col-four">
            <FormGroup>
              <Field
                id="email"
                name="email"
                label="Contact Email Address"
                component={TextInput}
                type="email"
                placeholder=""
                validate={[required, validEmail]}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row form className="user-row-four">
          <Col md={6} className="add-col user-col-three">
            <FormGroup>
              <Field
                id="password"
                name="password"
                component={TextInput}
                type={showPassword ? 'text' : 'password'}
                label="Password"
                className="form-control"
                validate={[required, validPassword]}
              />
            </FormGroup>
            <FormGroup>
              <Field
                id="re-enter password"
                name="re-enter password"
                component={TextInput}
                type={showPassword ? 'text' : 'password'}
                label="Re-Enter Password"
                className="form-control"
                validate={[required, validPassword]}
              />
            </FormGroup>
          </Col>
          <Col md={6} className="add-col user-col-three" style={{ display: 'flex', alignItems: 'flex-end' }}>
            <FormGroup>
              <Button onClick={handlePasswordVisibility}>{showPassword ? 'Hide' : 'Show'} Password</Button>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6} className="add-col">
            <FormGroup>
              <Field
                type="text"
                name="phone"
                id="phoneNumber"
                label="Phone Number"
                placeholder=""
                component={TextInput}
                className="form-control"
                validate={[required, validPhone]}
              />
            </FormGroup>
          </Col>
        </Row>
        {role === 'gateguard' && (
          <Row form className="user-row-four">
            <Col md={2} className="add-col">
              <FormGroup>
                <Field
                  name="musterAccess"
                  id="musterAccess"
                  label="Muster Access"
                  placeholder=""
                  component={TextInput}
                  className="form-control-input"
                  type="checkbox"
                  value="full:muster"
                />
              </FormGroup>
            </Col>
          </Row>
        )}
        <Row form>
          <Col lg={12}>
            <FormGroup>
              <Label for="exampleText" className="primary-heading">
                Notes
              </Label>
              <Field id="text" name="notes" component="textarea" className="form-control" />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col lg={12}>
            <FormGroup>
              <div className="flex flex-row">
                <div className="mr-2">
                  <ASCButton type="save" size="md" onClick={handleSubmit} disabled={pristine || submitting}>
                    Save
                  </ASCButton>
                </div>
                <div>
                  <ASCButton type="tertiary" size="md" onClick={() => history.goBack()}>
                    Cancel
                  </ASCButton>
                </div>
              </div>
            </FormGroup>
          </Col>
        </Row>
      </MainCard>
    </Form>
  );
};

AddUsersForm = reduxForm({
  form: 'addUser',
  initialValues: { musterAccess: true },
})(AddUsersForm);

const selector = formValueSelector('addUser');
AddUsersForm = connect((state) => {
  const role = selector(state, 'role');
  return { role };
})(AddUsersForm);

export default AddUsersForm;
