import React, { useState } from 'react';
import styled from 'styled-components';

const TextDelimiter = styled.span`
  padding: 0px 2px;
`;

const Pagination = ({ pageSize, totalItems, busy, getNextPage, getPrevPage }) => {
  const [page, changePage] = useState(1);

  const totalPages = Math.ceil(totalItems / pageSize);

  const prevPage = () => {
    if (page - 1 < 1) {
      changePage(1);
    } else {
      changePage(page - 1);
      getPrevPage();
    }
  };

  const nextPage = () => {
    if (page + 1 > totalPages) {
      changePage(totalPages);
    } else {
      changePage(page + 1);
      getNextPage(page + 1);
    }
  };

  return (
    <div className="ag-paging-panel ag-unselectable">
      <span className="ag-paging-row-summary-panel">
        <TextDelimiter className="ag-paging-row-summary-panel-number">{1 + pageSize * (page - 1)}</TextDelimiter>
        to
        <TextDelimiter className="ag-paging-row-summary-panel-number">
          {pageSize * page < totalItems ? pageSize * page : totalItems}
        </TextDelimiter>
        of
        <TextDelimiter className="ag-paging-row-summary-panel-number">{totalItems}</TextDelimiter>
      </span>
      <span className="ag-paging-page-summary-panel">
        <div className="ag-paging-button-wrapper ag-disabled">
          <span className="ag-icon ag-icon-previous" unselectable="on"></span>
          <button
            type="button"
            className="ag-paging-button"
            onClick={prevPage}
            disabled={busy}
            style={{ cursor: 'pointer' }}
          >
            Previous
          </button>
        </div>
        <span className="ag-paging-description">
          Page
          <TextDelimiter className="ag-paging-number">{page}</TextDelimiter>
          of
          <TextDelimiter className="ag-paging-number">{totalPages}</TextDelimiter>
        </span>
        <span className="ag-paging-number"></span>
        <div className="ag-paging-button-wrapper ag-disabled">
          <span className="ag-icon ag-icon-next" unselectable="on"></span>
          <button
            type="button"
            className="ag-paging-button"
            onClick={nextPage}
            disabled={busy}
            style={{ cursor: 'pointer' }}
          >
            Next
          </button>
        </div>
      </span>
    </div>
  );
};

export default Pagination;
