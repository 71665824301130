import ASCButton from '@alliancesafetycouncil/asc-button';
import MainCard from '@alliancesafetycouncil/asc-card';
import React from 'react';
import { connect } from 'react-redux';
import { Col, Form, FormGroup, Label, Row } from 'reactstrap';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import styled from 'styled-components';
import SelectInput from '../../../components/FormContainer/SelectInput';
import TextInput from '../../../components/FormContainer/TextInput';
import { oneOf, positiveInteger, required, validEmail, validNumber, validPhone } from '../../../library/Validation';

const NoFeature = styled.span`
  color: red;
`;

let EditSitesForm = ({
  history,
  handleSubmit,
  pristine,
  submitting,
  curriculums,
  companies,
  hasAutoCheckOutValue,
  timezones,
  company,
  initialValues,
}) => {
  const musterFeature = !company.config?.features || !company.config?.features.includes('muster');
  const blockFeature = !company.config?.features || !company.config?.features.includes('block');

  return (
    <Form onSubmit={handleSubmit}>
      <MainCard type="main">
        <Row form>
          <Col lg={6}>
            <FormGroup>
              <Field
                id="siteName"
                name="name"
                component={TextInput}
                type="text"
                label="Site Name"
                placeholder=""
                className="form-control"
                validate={[required]}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col lg={6}>
            <FormGroup>
              <Field
                id="status"
                name="status"
                className="form-control"
                label="Status"
                validate={[oneOf(['active', 'inactive'])]}
                component={SelectInput}
              >
                {' '}
                <option>Please Select</option>
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </Field>
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col lg={6}>
            <FormGroup>
              <Field
                id="timezone"
                name="config.timezone"
                component={SelectInput}
                className="form-control"
                label="Timezone"
                validate={[required]}
              >
                <option value={undefined}>Please Select</option>
                {timezones &&
                  timezones.map((e) => {
                    return (
                      <option key={e.timezone} value={e.timezone}>
                        {e.label}
                      </option>
                    );
                  })}
              </Field>
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col lg={6}>
            <FormGroup>
              <Label for="status" className="primary-heading">
                Auto Check-Out
              </Label>
              <Row form>
                <Col lg={1}>
                  <Field
                    id="hasAutoCheckOutValue"
                    name="hasAutoCheckOutValue"
                    className="form-control-input"
                    label="Auto Check-Out"
                    component="input"
                    type="checkbox"
                  />
                </Col>
              </Row>
              <Row form>
                <Col lg={2}>
                  {hasAutoCheckOutValue || initialValues.config.autocheckout !== 0 ? (
                    <React.Fragment>
                      <Field
                        id="autoCheckOut"
                        name="config.autocheckout"
                        className="form-control"
                        validate={[validNumber, positiveInteger]}
                        component={TextInput}
                        type="TextInput"
                        required={false}
                      />{' '}
                      hours after check-in
                    </React.Fragment>
                  ) : null}
                </Col>
              </Row>
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col lg={6} className={`${musterFeature && 'disabledFeature'}`}>
            <FormGroup>
              <Label for="status" className="primary-heading">
                Emergency/Muster List
              </Label>
              <Row form>
                <Col lg={1}>
                  <Field
                    id="hasMusterFeature"
                    name="hasMusterFeature"
                    className="form-control-input"
                    label="Emergency/Muster List"
                    component="input"
                    type="checkbox"
                    disabled={musterFeature}
                  />
                </Col>
                {musterFeature && <NoFeature>Feature disabled, please contact administrator</NoFeature>}
              </Row>
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col lg={6} className={`${blockFeature && 'disabledFeature'}`}>
            <FormGroup>
              <Label for="status" className="primary-heading">
                Block List
              </Label>
              <Row form>
                <Col lg={1}>
                  <Field
                    id="hasBlockFeature"
                    name="hasBlockFeature"
                    className="form-control-input"
                    label="Block List"
                    component="input"
                    type="checkbox"
                    disabled={blockFeature}
                  />
                </Col>
                {blockFeature && <NoFeature>Feature disabled, please contact administrator</NoFeature>}
              </Row>
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col lg={6}>
            <FormGroup>
              <Field
                id="company"
                name="company.network.id"
                component={SelectInput}
                className="form-control"
                label="Company"
                validate={[required]}
                disabled={true}
              >
                {companies &&
                  companies.map((company) => {
                    return (
                      <option key={company.network.id} value={company.network.id}>
                        {company.name}
                      </option>
                    );
                  })}
              </Field>
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col lg={6}>
            <FormGroup>
              <Field
                id="siteContactName"
                name="contact.name"
                component={TextInput}
                type="text"
                label="Site Contact (Name)"
                placeholder=""
                className="form-control"
                validate={[required]}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col lg={6}>
            <FormGroup>
              <Field
                id="contactPhone"
                name="contact.phone"
                component={TextInput}
                type="text"
                label="Contact Phone"
                placeholder=""
                className="form-control"
                validate={[required, validPhone]}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col lg={6}>
            <FormGroup>
              <Field
                id="email"
                name="contact.email"
                component={TextInput}
                type="email"
                placeholder=""
                label="Contact Email Address"
                className="form-control"
                validate={[required, validEmail]}
              />
            </FormGroup>
          </Col>
        </Row>
        <FormGroup>
          <Row form></Row>
          <Row form>
            <Col lg={12}>
              <Row form>
                <Col lg={6}>
                  <FormGroup>
                    <Field
                      id="ilevelCurriculum"
                      name="config.entry.iLevel.curriculum"
                      label="iLevel Curriculum"
                      component={SelectInput}
                      className="form-control"
                      validate={(required, validNumber)}
                    >
                      {curriculums.map((curriculum) => {
                        return (
                          <option key={curriculum.id} value={curriculum.id}>
                            {curriculum.id} - {curriculum.name}
                          </option>
                        );
                      })}
                    </Field>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>
        </FormGroup>
        <Row form>
          <Col lg={12}>
            <FormGroup>
              <Label for="exampleText" className="primary-heading">
                Notes
              </Label>
              <Field id="text" name="notes" component="textarea" className="form-control" />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col lg={12}>
            <FormGroup>
              <div className="flex flex-row">
                <div className="mr-2">
                  <ASCButton type="save" size="md" onClick={handleSubmit} disabled={pristine || submitting}>
                    Save
                  </ASCButton>
                </div>
                <div>
                  <ASCButton type="tertiary" size="md" onClick={() => history.goBack()}>
                    Cancel
                  </ASCButton>
                </div>
              </div>
            </FormGroup>
          </Col>
        </Row>
      </MainCard>
    </Form>
  );
};

EditSitesForm = reduxForm({
  form: 'editSite',
  enableReinitialize: true,
})(EditSitesForm);

const selector = formValueSelector('editSite');
EditSitesForm = connect((state) => {
  const hasAutoCheckOutValue = selector(state, 'hasAutoCheckOutValue');
  return { hasAutoCheckOutValue };
})(EditSitesForm);

export default EditSitesForm;
