import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getGatesByNetworkAndSite } from "../../../actions/Gates/Gates";
import TableProvider from "../../../components/TableProvider/TableProvider";

class GatesTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columnDefs: [
        { headerName: "Gate Name", field: "name", cellRenderer: "renderName" },
        { headerName: "Parent Site", field: "site" },
        { headerName: "Parent Company", field: "company" },
        { headerName: "Contact Name", field: "contact" },
        { headerName: "Contact Phone", field: "phone" },
        { headerName: "Notes", field: "notes" },
        { headerName: "Status", field: "status" },
      ],
      customCells: {
        renderName: (row) => {
          return (
            row.data && (
              <Link to={`/gates/gate-details/${row.data.id}/${props.match.params.networkId}/details`}>
                {row.data.name}
              </Link>
            )
          );
        },
      },
      options: [],
    };
  }

  render() {
    return (
      <TableProvider
        {...this.props}
        columnDefs={this.state.columnDefs}
        getData={() =>
          this.props.GetGatesByNetworkAndSite(this.props.match.params.networkId, this.props.match.params.id)
        }
        customCells={this.state.customCells}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    busy: state.gateService.busy,
    res: state.gateService.res,
    err: state.gateService.err,
    data: state.gateService.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetGatesByNetworkAndSite: (networkId, siteId) => dispatch(getGatesByNetworkAndSite(networkId, siteId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GatesTab);
