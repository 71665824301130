import MainCard from '@alliancesafetycouncil/asc-card';
import PageTitle from '@alliancesafetycouncil/asc-page-title';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import { getAuth } from '../../../actions/Auth/Auth';

class SignIn extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: '',
      password: '',
      loginErrors: '',
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleSubmit(event) {
    const { username, password } = this.state;
    const { GetAuth } = this.props;

    GetAuth(username, password);

    event.preventDefault();
  }

  render() {
    const { username, password } = this.state;
    const { auth, busy, error, role } = this.props;
    const companyId = window.user.company_id;
    const siteId = window.user.site_id;
    const networkId = window.user.network_id;

    return (
      <div className="sign-in container mt-8 md:w-1/2">
        <div className="mb-6 text-4xl text-center">
          <span className="font-bold text-black-600">Gate</span>
          <span className="font-normal text-blue-500">Check</span>
        </div>
        <Form>
          <MainCard type="main">
            <PageTitle>Sign In</PageTitle>
            <br></br>
            <p className={`form-error ${error ? 'show' : ''}`}>{error}</p>
            <FormGroup>
              <Label for="exampleEmail">Email Address</Label>
              <Input
                value={username}
                onChange={this.handleChange}
                type="username"
                name="username"
                id="username"
                placeholder="Email Address"
                className="username"
              />
            </FormGroup>
            <FormGroup>
              <Label for="examplePassword">Password</Label>
              <Input
                value={password}
                onChange={this.handleChange}
                type="password"
                name="password"
                id="password"
                placeholder="Password"
                className="password"
              />
            </FormGroup>
            <Button className="sign-in button" onClick={this.handleSubmit}>
              {busy ? 'Signing in...' : 'Sign in'}
            </Button>
            <br></br>
            <div>
              <p>
                <a href="#/forgot-password">Forgot Password</a>
              </p>
            </div>
          </MainCard>
        </Form>
        <div>
          {/* Auth & Redirect Handler for Superadmin */}
          {role === 'superadmin' && auth && (
            <Redirect
              to={{
                pathname: '/companies',
                state: { from: window.location },
              }}
            />
          )}
          {/* Auth & Redirect Handler for Owneradmin */}
          {role === 'owneradmin' && auth && (
            <Redirect
              to={{
                pathname: `/companies/company-details/${networkId}/${companyId}/activity-report`,
                state: { from: window.location },
              }}
            />
          )}
          {/* Auth & Redirect Handler for Siteadmin */}
          {role === 'siteadmin' && auth && (
            <Redirect
              to={{
                pathname: `/sites/site-details/${siteId}/${networkId}/activity-report`,
                state: { from: window.location },
              }}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.authService.auth,
    busy: state.authService.busy,
    error: state.authService.error,
    role: state.authService.role,
    token: state.authService.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetAuth: (username, password) => dispatch(getAuth(username, password)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
