import axios from "axios";
import Config from "../../config/config.json";

export function getProfile(userId, networkId) {
  const networkPath = networkId ? `?networkId=${networkId}` : "";
  const url = userId ? `${Config.api.endpoint}/users/${userId}${networkPath}` : `${Config.api.endpoint}/users/self`;

  return async (dispatch) => {
    dispatch(profileRequest());
    axios
      .get(url)
      .then((res) => {
        dispatch(profileSuccess(res));
      })
      .catch((err) => {
        dispatch(profileFailure(err));
      });
  };
}

export const PROFILE_REQUEST = "PROFILE_REQUEST";
function profileRequest() {
  return {
    type: PROFILE_REQUEST,
  };
}

export const PROFILE_SUCCESS = "PROFILE_SUCCESS";
function profileSuccess(data) {
  return {
    type: PROFILE_SUCCESS,
    data,
  };
}

export const PROFILE_FAILURE = "PROFILE_FAILURE";
function profileFailure(err) {
  return {
    type: PROFILE_FAILURE,
    err,
  };
}
