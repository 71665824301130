import PageTitle from '@alliancesafetycouncil/asc-page-title';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { getGate } from '../../../actions/Gates/Gates';
import { clearCurriculums, getCurriculums } from '../../../actions/Networks/Networks';
import FormContainer from '../../../components/FormContainer/FormContainer';
import EditGateForm from './EditGateForm';
class EditGate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      gateId: this.props.match.params.id,
      networkId: this.props.match.params.networkId,
      error: false,
      success: false,
      message: null,
    };
  }

  componentDidMount() {
    const { networkId } = this.state;
    this.props.ClearCurriculums();

    if (networkId) {
      this.props.GetCurriculums(networkId);
      this.props.GetGate(this.state.gateId, this.state.networkId);
    }
  }

  render() {
    const { dataSite, details, curriculums, networkBusy } = this.props;
    const { gateId, networkId } = this.state;

    return (
      <div className="flex flex-1 flex-col">
        <Breadcrumb tag="nav" listTag="div">
          <BreadcrumbItem tag="a" href="#/gates" className="bread-crumb-root">
            Gates
          </BreadcrumbItem>
          <BreadcrumbItem>Add Gate</BreadcrumbItem>
        </Breadcrumb>
        <PageTitle icon="gate">Edit Gate</PageTitle>
        <FormContainer
          {...this.props}
          entity="gates"
          action="edit"
          redirect={`/gates/gate-details/${gateId}/${networkId}/details`}
          Form={EditGateForm}
          handleValues={(values) => {
            return {
              site: {
                id: values.site.id,
              },
              config: values.config,
              notes: values.notes,
              contact: values.contact,
              name: values.name,
              status: 'active',
            };
          }}
          initialValues={details}
          companies={[details.company]}
          sites={dataSite}
          networkBusy={networkBusy}
          curriculums={curriculums}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    details: state.gateService.details,
    companies: state.companyService.data,
    sites: state.siteService.data,
    curriculums: state.networkService.curriculums,
    networkBusy: state.networkService.busy,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetGate: (gateId, networkId) => dispatch(getGate(gateId, networkId)),
    GetCurriculums: (networkId) => dispatch(getCurriculums(networkId)),
    ClearCurriculums: () => dispatch(clearCurriculums()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditGate);
