import MainCard from '@alliancesafetycouncil/asc-card';
import PageTitle from '@alliancesafetycouncil/asc-page-title';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { getUsers } from '../../../actions/Users/Users';
import TableProvider from '../../../components/TableProvider/TableProvider';

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      params: {
        direction: 'asc',
        limit: 1000,
        offset: 0,
        sort: 'id',
      },
      columnDefs: [
        { headerName: 'User', field: 'name', cellRenderer: 'renderName' },
        { headerName: 'Company', field: 'company' },
        { headerName: 'Role', field: 'roles' },
        { headerName: 'Muster Access', field: 'muster' },
        { headerName: 'Login Name', field: 'login' },
        { headerName: 'Notes', field: 'notes' },
        {
          headerName: 'Status',
          field: 'status',
          filter: 'agSetColumnFilter',
          valueFormatter: (params) => params.value.charAt(0).toUpperCase() + params.value.slice(1, params.value.length),
          filterParams: {
            newRowsAction: 'keep',
          },
        },
      ],
      customCells: {
        renderName: (row) => {
          const networkId = row.data.network ? `/${row.data.network.id}` : '/super';
          return <Link to={`/users/user-details/${row.data.id}${networkId}/details`}>{row.data.name}</Link>;
        },
      },
      config: {
        add: {
          path: '#/users/add-user',
          label: 'Add User',
        },
      },
    };
  }

  render() {
    const { direction, limit, offset, sort } = this.state.params;

    return (
      <Fragment>
        <Breadcrumb tag="nav" listTag="div">
          <BreadcrumbItem active> All Users</BreadcrumbItem>
        </Breadcrumb>
        <PageTitle icon="user">All Users</PageTitle>
        <MainCard type="main">
          <div className="ag-theme-balham flex flex-1 flex-col">
            <TableProvider
              {...this.props}
              columnDefs={this.state.columnDefs}
              getData={() => this.props.GetUsers(direction, limit, offset, sort)}
              config={this.state.config}
              customCells={this.state.customCells}
              defaultColDef={this.state.defaultColDef}
            />
          </div>
        </MainCard>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    busy: state.userService.busy,
    res: state.userService.res,
    err: state.userService.err,
    data: state.userService.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetUsers: (direction, limit, offset, sort) => dispatch(getUsers(direction, limit, offset, sort)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
