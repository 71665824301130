import { AllModules } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import React, { Component } from 'react';
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Form, Input } from 'reactstrap';
import styled from 'styled-components';
import { debounce } from '../../library/Utilities';
import Pagination from '../ActivityReport/Pagination';

let filter = '';

const OverlayContainer = styled.div`
  position: absolute;
  background: white;
  display: flex;
  width: calc(100% - 12px);
  height: calc(100% - 40px);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  bottom: 4px;
  left: 6px;
  box-shadow: 0 0 5px 3px #fff;
  background: rgba(255, 255, 255, 0.75);
`;

const Overlay = styled.div`
  transform: translateY(-40px);
`;

class TableProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dropdownOpen: false,
      columns: [...this.props.columnDefs],
      filter: '',
      modules: AllModules,
      defaultColDef: {
        sortable: true,
        flex: 1,
        minWidth: 100,
        filter: true,
        ...props.defaultColDef,
      },
      frameworkComponents: {
        ...props.customCells,
      },
    };

    filter = '';
  }

  handleDebounce = debounce(() => {
    filter = this.state.filter;
    this.gridApi.onFilterChanged();
  }, 1000);

  handleChange = (value) => {
    this.setState({
      filter: value,
    });

    this.handleDebounce();
  };

  isExternalFilterPresent = () => {
    return filter.length > 0;
  };

  doesExternalFilterPass = (node) => {
    const { format } = this.props;

    if (filter.length) {
      return Object.keys(node.data)
        .map((key) => {
          if (key == 'id' || key == 'networkId') {
            return false;
          }

          if (format && key.includes('date')) {
            return node.data[key] && format(node.data[key], node.data.timezone).includes(filter);
          }

          return node.data[key] && node.data[key].toString().toLowerCase().includes(filter.toLowerCase());
        })
        .find((item) => item === true);
    }
    return true;
  };

  handleRowSelection = (data) => {
    this.setState({
      selected: data,
    });
  };

  toggleDropdown = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  };

  export = (format) => {
    switch (format) {
      case 'csv':
        this.gridApi.exportDataAsCsv(this.state.export);
        break;
      case 'excel':
        this.gridApi.exportDataAsExcel(this.state.export);
        break;
      default:
        return false;
    }
  };

  setDefaultFilter = () => {
    const instance = this.gridApi.getFilterInstance('status');

    if (instance) {
      instance.selectNothing();
      instance.selectValue('active');
      instance.applyModel();
    }

    this.gridApi.onFilterChanged();
  };

  onFirstDataRendered = () => {
    if (this.props.defaultFilter) {
      this.setDefaultFilter();
    }
  };

  onGridReady = async (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    this.props.getData();
  };

  handleAdd = (condition, alt, add) => {
    if (condition) {
      alt();
    } else {
      window.location = add;
    }
  };

  handleOverlay = () => {
    // disabled overlay
    if (this.props.disabled === true) {
      return (
        <OverlayContainer>
          <Overlay style={{ fontWeight: 'bold', padding: '10px', border: '2px solid red' }}>
            This feature has been disabled. <br />
            Please contact your administrator.
          </Overlay>
        </OverlayContainer>
      );
    }

    //busy overlay
    if (this.props.busy === true) {
      return (
        <OverlayContainer>
          <Overlay className="ag-overlay-loading-center">Loading...</Overlay>
        </OverlayContainer>
      );
    }

    // no-rows overlay
    if (this.props.busy === false && this.props.res === true && !this.props.data.length) {
      return (
        <OverlayContainer>
          <Overlay className="ag-overlay-loading-center">No Rows To Show</Overlay>
        </OverlayContainer>
      );
    }
  };

  getNextPage = async (page) => {
    // dont fetch if we already have this page
    if (page > this.props.page) {
      await this.props.getData(this.props.offset, true, page);
    }

    this.gridApi.paginationGoToNextPage();
  };

  getPrevPage = () => {
    this.gridApi.paginationGoToPreviousPage();
  };

  render() {
    const {
      data,
      details = {},
      tabs,
      totalsComponent,
      config,
      pagination,
      busy,
      enablePagination = false,
    } = this.props;
    const { columns } = this.state;
    const { name } = details;

    const active = window.location.href.split('/').reverse()[0].toLowerCase();
    const tab = tabs ? tabs.find((tab) => tab.path.includes(active)) : config;
    const isDetails = active === 'details';

    let add;
    if (tab.add) {
      add = `${tab.add.path}`;
    }

    if (details.entity) {
      add += `/${details.entity.id}`;
    }

    if (details.network) {
      add += `/${details.network.id}`;
    }

    return (
      <div className="flex flex-1 flex-col">
        <div className="mb-5">
          <Form>
            <Input
              value={this.state.filter}
              type="text"
              name="filter"
              id="filter"
              placeholder="Filter by anything"
              className="filter-anything"
              onChange={(e) => this.handleChange(e.target.value)}
            />
            <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggleDropdown} className="export">
              <DropdownToggle caret color="secondary">
                Export
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-right">
                <DropdownItem onClick={() => this.export('excel')}>Excel</DropdownItem>
                <DropdownItem onClick={() => this.export('csv')}>CSV</DropdownItem>
              </DropdownMenu>
            </Dropdown>
            {!isDetails && tab.add && (
              <Button
                disabled={tab.busy ? this.props[tab.busy] : false}
                style={{ zIndex: '999', position: 'relative', float: 'right', color: '#fff' }}
                color="primary"
                className="d-sm-block"
                tag="a"
                onClick={() =>
                  this.handleAdd(
                    tab.condition && tab.condition.eval(this.props[tab.condition.input], name),
                    tab.alt,
                    add
                  )
                }
              >
                {tab.add.label}
              </Button>
            )}
          </Form>
        </div>
        {totalsComponent && totalsComponent()}
        <div className="ag-theme-balham flex flex-1 flex-col" style={{ position: 'relative' }}>
          <AgGridReact
            modules={this.state.modules}
            defaultColDef={this.state.defaultColDef}
            columnDefs={columns}
            onRowClicked={(e) => this.handleRowSelection(e.data)}
            frameworkComponents={this.state.frameworkComponents}
            isExternalFilterPresent={this.isExternalFilterPresent}
            doesExternalFilterPass={this.doesExternalFilterPass}
            onGridReady={this.onGridReady}
            rowData={data}
            animateRows={false}
            onFirstDataRendered={this.onFirstDataRendered}
            paginationPageSize={1000}
            pagination={true}
            suppressPaginationPanel={true}
            suppressLoadingOverlay={true}
            suppressNoRowsOverlay={true}
          />
          {this.handleOverlay()}
        </div>
        {enablePagination && (
          <Pagination
            busy={busy}
            getPrevPage={this.getPrevPage}
            getNextPage={(page) => this.getNextPage(page)}
            pageSize={1000}
            totalItems={pagination.totalItems}
          />
        )}
      </div>
    );
  }
}

TableProvider.defaultProps = {
  defaultFilter: true,
  disabled: false,
};

export default TableProvider;
