import Moment from 'moment-timezone';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getCompany } from '../../../actions/Companies/Companies';
import { getActiveEmergencies, getSiteEmergencies } from '../../../actions/Sites/Sites';
import TableProvider from '../../../components/TableProvider/TableProvider';
import { relativeFormats } from '../../../library/Utilities';

class Emergencies extends Component {
  constructor(props) {
    super(props);

    this.state = {
      params: {
        direction: 'asc',
        limit: 100,
        offset: 0,
        sort: 'id',
      },
      defaultColDef: {
        resizable: true,
      },
      columnDefs: [
        { headerName: 'Emergency Name', field: 'name', cellRenderer: 'renderName' },
        {
          headerName: 'Start Date',
          field: 'start_date',
          cellRenderer: (data) => {
            return this.formatDate(data.data.start_date, data.data.timezone);
          },
        },
        {
          headerName: 'Start Time',
          field: 'start_date',
          cellRenderer: (data) => {
            return this.formatTime(data.data.start_date, data.data.timezone);
          },
        },
        {
          headerName: 'End Date',
          field: 'end_date',
          cellRenderer: (data) => {
            return data.data.end_date && this.formatDate(data.data.end_date, data.data.timezone);
          },
        },
        {
          headerName: 'End Time',
          field: 'end_date',
          cellRenderer: (data) => {
            return data.data.end_date && this.formatTime(data.data.end_date, data.data.timezone);
          },
        },
        { headerName: 'On Site', field: 'report.onsite' },
        { headerName: 'Present', field: 'report.present' },
        { headerName: 'Absent', field: 'report.absent' },
        {
          headerName: 'Status',
          field: 'status',
          filter: 'agSetColumnFilter',
          valueFormatter: (params) => params.value.charAt(0).toUpperCase() + params.value.slice(1, params.value.length),
          filterParams: {
            newRowsAction: 'keep',
          },
        },
      ],
      customCells: {
        renderName: (row) => {
          return (
            row.data && (
              <Link to={`/emergency/emergency-details/${row.data.id}/${props.match.params.networkId}/muster-report`}>
                {row.data.name}
              </Link>
            )
          );
        },
      },
      options: [],
    };
  }

  formatDate = (value, timezone) => {
    return Moment(value).tz(timezone).calendar(relativeFormats());
  };

  formatTime = (value, timezone) => {
    return Moment(value).tz(timezone).format('h:mm A z');
  };

  format = (value, timezone) => {
    return Moment(value).tz(timezone).format('M/D/YYYY h:mma');
  };

  isDisabled = () => {
    return this.props.companyDetails.hasMusterFeature !== null
      ? !this.props.companyDetails.hasMusterFeature
      : this.props.companyDetails.hasMusterFeature;
  };

  componentDidUpdate(prevProps) {
    if (this.props.siteDetails.company.id && this.props.siteDetails.company.id !== prevProps.siteDetails.company.id) {
      this.props.GetCompany(this.props.siteDetails.company.id);
    }
  }

  componentDidMount() {
    this.props.GetActiveEmergencies();
  }

  render() {
    const { id } = this.props.match.params;
    const { direction, limit, offset, sort } = this.state.params;

    return (
      <TableProvider
        {...this.props}
        defaultFilter={false}
        disabled={this.isDisabled()}
        columnDefs={this.state.columnDefs}
        getData={() =>
          this.props.GetSiteEmergencies(id, this.props.match.params.networkId, direction, limit, offset, sort)
        }
        customCells={this.state.customCells}
        format={this.format}
        defaultColDef={this.state.defaultColDef}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    busy: state.siteService.busy,
    res: state.siteService.res,
    err: state.siteService.err,
    data: state.siteService.emergencies,
    companyDetails: state.companyService.details,
    siteDetails: state.siteService.details,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetCompany: (id) => dispatch(getCompany(id)),
    GetActiveEmergencies: () => dispatch(getActiveEmergencies()),
    GetSiteEmergencies: (id, networkdId, direction, limit, offset, sort) =>
      dispatch(getSiteEmergencies(id, networkdId, direction, limit, offset, sort)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Emergencies);
