import PageTitle from "@alliancesafetycouncil/asc-page-title";
import React, { Component } from "react";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import FormContainer from "../../../components/FormContainer/FormContainer";
import AddCompanyForm from "./AddCompanyForm";

class AddCompanies extends Component {
  render() {
    return (
      <div className="flex flex-1 flex-col">
        <Breadcrumb tag="nav" listTag="div">
          <BreadcrumbItem tag="a" href="#/companies" className="bread-crumb-root">
            Companies
          </BreadcrumbItem>
          <BreadcrumbItem tag="a">Add Company</BreadcrumbItem>
        </Breadcrumb>
        <PageTitle icon="company">Add Company</PageTitle>
        <FormContainer
          {...this.props}
          entity="companies"
          action="add"
          redirect={"/companies"}
          Form={AddCompanyForm}
          handleValues={(values) => {
            let features = [];
            if (values.hasMusterFeature) {
              features.push("muster");
            }
            if (values.hasBlockFeature) {
              features.push("block");
            }

            return {
              name: values.name,
              status: values.status,
              site: values.site,
              contact: values.contact,
              entry: values.entry,
              config: {
                features,
              },
              notes: values.notes,
            };
          }}
        />
      </div>
    );
  }
}

export default AddCompanies;
