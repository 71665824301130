import axios from "axios";
import Config from "../../config/config.json";

export function getCurriculums(networkId) {
  return async (dispatch) => {
    dispatch(getCurriculumsRequest());
    return axios
      .get(`${Config.api.endpoint}/networks/${networkId}/entry`)
      .then((res) => {
        dispatch(getCurriculumsSuccess(res.data));
      })
      .catch((err) => {
        dispatch(getCurriculumsFailure("Could not fetch."));
      });
  };
}

export const GET_CURRICULUMS_REQUEST = "GET_CURRICULUMS_REQUEST";
function getCurriculumsRequest() {
  return {
    type: GET_CURRICULUMS_REQUEST,
  };
}

export const GET_CURRICULUMS_SUCCESS = "GET_CURRICULUMS_SUCCESS";
function getCurriculumsSuccess(data) {
  return {
    type: GET_CURRICULUMS_SUCCESS,
    data,
  };
}

export const GET_CURRICULUMS_FAILURE = "GET_CURRICULUMS_FAILURE";
function getCurriculumsFailure(error) {
  return {
    type: GET_CURRICULUMS_FAILURE,
    error,
  };
}

export const CLEAR_CURRICULUMS = "CLEAR_CURRICULUMS";
export function clearCurriculums() {
  return {
    type: CLEAR_CURRICULUMS,
  };
}
