import PageTitle from '@alliancesafetycouncil/asc-page-title';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { getCompany } from '../../../actions/Companies/Companies';
import { clearCurriculums, getCurriculums } from '../../../actions/Networks/Networks';
import { getSite } from '../../../actions/Sites/Sites';
import FormContainer from '../../../components/FormContainer/FormContainer';
import { getTimezones } from '../../../library/Utilities';
import EditSiteForm from './EditSiteForm';

class EditSites extends Component {
  constructor(props) {
    super(props);

    this.state = {
      siteId: this.props.match.params.id,
      networkId: this.props.match.params.networkId,
      error: false,
      success: false,
      message: null,
    };
  }

  componentDidMount() {
    this.props.GetSite(this.state.networkId, this.state.siteId, () => {
      this.props.GetCompany(this.props.details.company.id);
    });
    this.props.ClearCurriculums();
    this.props.getCurriculums(this.state.networkId);
  }

  render() {
    const { curriculums, details, company } = this.props;

    return (
      <div className="flex flex-1 flex-col">
        <Breadcrumb tag="nav" listTag="div">
          <BreadcrumbItem tag="a" href="#/sites" className="bread-crumb-root">
            Sites
          </BreadcrumbItem>
          <BreadcrumbItem>Edit Site</BreadcrumbItem>
        </Breadcrumb>
        <PageTitle icon="site">Edit Site</PageTitle>
        <FormContainer
          {...this.props}
          entity="sites"
          action="edit"
          Form={EditSiteForm}
          handleValues={(values) => {
            let features = [];
            if (!values.hasAutoCheckOutValue) {
              values.config.autocheckout = 0;
            }
            if (values.hasMusterFeature) {
              features.push('muster');
            }
            if (values.hasBlockFeature) {
              features.push('block');
            }

            return {
              entry: values.entry,
              notes: values.notes,
              contact: values.contact,
              config: {
                ...values.config,
                features,
              },
              name: values.name,
              status: values.status,
            };
          }}
          company={company}
          companies={[details.company]}
          curriculums={curriculums}
          initialValues={{
            ...details,
            hasMusterFeature: details.config && details.config.features && details.config.features.includes('muster'),
            hasBlockFeature: details.config && details.config.features && details.config.features.includes('block'),
          }}
          timezones={getTimezones()}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    company: state.companyService.details,
    details: state.siteService.details,
    companies: state.companyService.data,
    curriculums: state.networkService.curriculums,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetCompany: (id) => dispatch(getCompany(id)),
    GetSite: (networkId, siteId) => dispatch(getSite(networkId, siteId)),
    getCurriculums: (networkId) => dispatch(getCurriculums(networkId)),
    ClearCurriculums: () => dispatch(clearCurriculums()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditSites);
