import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Form, Input, Row } from 'reactstrap';
import { getCompanyActivity } from '../../../actions/Activity/Activity';
import ActivityReport from '../../../components/ActivityReport/ActivityReport';
import { DateFilter } from '../../../components/ActivityReport/DateFilter';

class ActivityTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dropdownOpen: false,
      filter: '',
      export: null,
      startDate: null,
      endDate: null,
      params: {
        limit: 100,
        offset: 0,
      },
    };
  }

  setContext = (context) => {
    this.setState({
      export: context,
    });
  };

  export = () => {
    this.state.export();
  };

  toggleDropdown = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleDate = (value, date) => {
    this.setState({
      [date]: value,
    });
  };

  render() {
    const { startDate, endDate } = this.state;
    const { networkId } = this.props.match.params;

    return (
      <div
        style={{
          flex: '1',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Row
          className="mb-3 mt-1 text-right"
          style={{
            zIndex: '999',
            position: 'relative',
            display: 'inline-flex',
            alignSelf: 'flex-start',
            flexDirection: 'row',
          }}
        >
          <Col>
            <div className="options">
              <Form style={{ display: 'flex' }}>
                <Input
                  value={this.state.filter}
                  type="text"
                  name="filter"
                  id="filter"
                  placeholder="Filter by anything"
                  className="filter-anything"
                  onChange={(e) => this.handleChange(e)}
                />
                <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggleDropdown} className="export">
                  <DropdownToggle caret color="secondary">
                    Export
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-right">
                    <DropdownItem onClick={() => this.export()}>Excel</DropdownItem>
                  </DropdownMenu>
                </Dropdown>
                <div className="filter-date">
                  <DateFilter startDate={startDate} endDate={endDate} handleDate={this.handleDate} />
                </div>
              </Form>
            </div>
          </Col>
        </Row>
        <div
          className="ag-grid-wrap"
          style={{
            flex: '1',
            display: 'flex',
          }}
        >
          <div
            className="ag-theme-balham"
            style={{
              flex: '1',
              width: '100%',
            }}
          >
            <ActivityReport
              getData={(offset, paginate, page) => this.props.GetActivityByNetwork(networkId, offset, paginate, page)}
              setContext={this.setContext}
              filter={this.state.filter}
              busy={this.props.busy}
              noData={this.props.noData}
              res={this.props.res}
              err={this.props.err}
              data={this.props.data}
              pagination={this.props.pagination}
              page={this.props.page}
              startDate={startDate && new Date(startDate)}
              endDate={endDate && new Date(endDate)}
              offset={this.props.offset}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    offset: state.activityService.offset,
    noData: state.activityService.noData,
    busy: state.activityService.busy,
    res: state.activityService.res,
    err: state.activityService.err,
    data: state.activityService.data,
    pagination: state.activityService.pagination,
    page: state.activityService.page,
    details: state.companyService.details,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetActivityByNetwork: (networkId, offset, paginate, page) =>
      dispatch(getCompanyActivity(networkId, offset, paginate, page)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivityTab);
