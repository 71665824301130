import Companies from './views/Pages/Companies';
import AddCompany from './views/Pages/Companies/AddCompany';
import CompanyDetails from './views/Pages/Companies/CompanyDetails';
import EditCompany from './views/Pages/Companies/EditCompany';
import AddEmergency from './views/Pages/Emergency/AddEmergency';
import EditEmergency from './views/Pages/Emergency/EditEmergency';
import EmergencyDetails from './views/Pages/Emergency/EmergencyDetails';
import Gates from './views/Pages/Gates';
import AddGate from './views/Pages/Gates/AddGate';
import EditGate from './views/Pages/Gates/EditGate';
import GateDetails from './views/Pages/Gates/GateDetails';
import Home from './views/Pages/Home';
import AddMusterLocation from './views/Pages/Muster/AddMusterLocation';
import EditMusterLocation from './views/Pages/Muster/EditMusterLocation';
import MusterDetails from './views/Pages/Muster/MusterDetails';
import Sites from './views/Pages/Sites';
import AddSite from './views/Pages/Sites/AddSite';
import EditSite from './views/Pages/Sites/EditSite';
import SiteDetails from './views/Pages/Sites/SiteDetails';
import Support from './views/Pages/Support/Support';
import Users from './views/Pages/Users';
import AddUser from './views/Pages/Users/AddUser';
import EditUser from './views/Pages/Users/EditUser';
import UserDetails from './views/Pages/Users/UserDetails';
import Visitors from './views/Pages/Visitors';
import VisitorDetails from './views/Pages/Visitors/VisitorDetails';
import ChangePassword from './views/Profile/ChangePassword';
import EditProfile from './views/Profile/EditProfile';
import Profile from './views/Profile/Profile';

const ADD_COMPANY = [
  { path: '/companies/add-company', name: 'Add Company', component: AddCompany },
  { path: '/companies', name: 'Company', component: Companies },
];

const EDIT_COMPANY = [
  { path: '/companies/company-details/:networkId/:id', name: 'Edit Company', component: CompanyDetails },
  { path: '/companies/edit-company/:networkId/:id', name: 'Edit Company', component: EditCompany },
];

let routes = (role) => {
  let paths = [
    { path: '/', exact: true, name: '' },
    { path: '/home', name: 'Home', component: Home },
    { path: '/profile/edit-profile', name: 'Edit Profile', component: EditProfile },
    { path: '/profile/change-password/:id/:networkId', name: 'Change Password', component: ChangePassword },
    { path: '/profile/change-password', name: 'Change Password', component: ChangePassword },
    { path: '/profile', name: 'Profile', component: Profile },
    { path: '/users/user-details/:id/:networkId', name: 'User Detail', component: UserDetails },
    { path: '/users/user-details/:id', name: 'User Detail', component: UserDetails },
    { path: '/users/edit-user/:id/:networkId', name: 'Edit User', component: EditUser },
    { path: '/users/edit-user/:id', name: 'Edit User', component: EditUser },
    { path: '/users/add-user', name: 'Add User', component: AddUser },
    { path: '/users', name: 'User', component: Users },
    { path: '/sites/site-details/:id/:networkId', name: 'Site Detail', component: SiteDetails },
    { path: '/sites/edit-site/:id/:networkId', name: 'Edit Site', component: EditSite },
    { path: '/sites/add-site/:networkId', name: 'Add Site', component: AddSite },
    { path: '/sites/add-site', name: 'Add Site', component: AddSite },
    { path: '/sites', name: 'Sites', component: Sites },
    { path: '/gates/gate-details/:id/:networkId', name: 'Gate Details', component: GateDetails },
    { path: '/gates/edit-gate/:id/:networkId', name: 'Edit Gate', component: EditGate },
    { path: '/gates/add-gate/:id/:networkId', name: 'Add Gate', component: AddGate },
    { path: '/gates/add-gate/:networkId', name: 'Add Site', component: AddGate },
    { path: '/gates/add-gate', name: 'Add Gate', component: AddGate },
    { path: '/gates', name: 'Gates', component: Gates },
    { path: '/muster-location/location-details/:id/:networkId', name: 'Location Details', component: MusterDetails },
    { path: '/muster-location/edit-location/:id/:networkId', name: 'Edit Location', component: EditMusterLocation },
    { path: '/muster-location/add-location/:id/:networkId', name: 'Add Location', component: AddMusterLocation },
    { path: '/muster-location/add-location', name: 'Add Location', component: AddMusterLocation },
    { path: '/emergency/emergency-details/:id/:networkId', name: 'Emergency Details', component: EmergencyDetails },
    { path: '/emergency/edit-emergency/:id/:networkId', name: 'Edit Emergency', component: EditEmergency },
    { path: '/emergency/add-emergency/:id/:networkId', name: 'Add Emergency', component: AddEmergency },
    { path: '/emergency/add-emergency', name: 'Add Emergency', component: AddEmergency },
    { path: '/support', name: 'Support', component: Support },
    { path: '/visitors/visitors-details/:id/:networkId', name: 'Visitor Detail', component: VisitorDetails },
    { path: '/visitors/visitors-details/:id', name: 'Visitor Detail', component: VisitorDetails },
    { path: '/visitors', name: 'Visitors', component: Visitors },
  ];

  if (role === 'superadmin') {
    paths.push(...EDIT_COMPANY);
    paths.push(...ADD_COMPANY);
  }

  if (role === 'owneradmin') {
    paths.push(...EDIT_COMPANY);
  }

  return paths;
};

export default routes;
