import axios from 'axios';
// import { setToastSuccess, setToastFailure } from "../Toast/Toast"
import Config from '../../config/config.json';

export function getVisitors(offset, paginate = false, page) {
  return async (dispatch) => {
    if (!paginate) {
      dispatch(clearVisitorData());
    }

    let url = `${Config.api.endpoint}/visitors?limit=1000`;

    if (offset) {
      url += `&after=${offset}`;
    }

    dispatch(getVisitorsRequest());
    return axios
      .get(url)
      .then((res) => {
        console.log(res);
        dispatch(getVisitorsSuccess(res.data.data.visitors, res.data.data.pagination, page));
      })
      .catch((_) => {
        dispatch(getVisitorsFailure('Could not fetch.'));
      });
  };
}

export function getVisitor(visitorId, networkId) {
  const networkPath = networkId ? `?networkId=${networkId}` : '';
  return async (dispatch) => {
    dispatch(getVisitorRequest());
    return axios
      .get(`${Config.api.endpoint}/visitors/${visitorId}${networkPath}`)
      .then((res) => {
        dispatch(getVisitorSuccess(res.data));
      })
      .catch((err) => {
        dispatch(getVisitorFailure(err));
      });
  };
}

export function editVisitor(payload, id, networkId) {
  const networkPath = networkId ? `?networkId=${networkId}` : ``;

  return async (dispatch) => {
    dispatch(editVisitorRequest());
    return axios
      .patch(`${Config.api.endpoint}/visitors/${id}${networkPath}`, payload)
      .then((res) => {
        dispatch(updateVisitor(payload));
      })
      .catch((err) => {
        dispatch(editVisitorFailure(err));
      });
  };
}

export const CLEAR_VISITOR_DATA = 'CLEAR_VISITOR_DATA';
function clearVisitorData() {
  return {
    type: CLEAR_VISITOR_DATA,
  };
}

export const EDIT_VISITOR_REQUEST = 'EDIT_VISITOR_REQUEST';
function editVisitorRequest() {
  return {
    type: EDIT_VISITOR_REQUEST,
  };
}

export const EDIT_VISITOR_FAILURE = 'EDIT_VISITOR_FAILURE';
function editVisitorFailure(err) {
  return {
    type: EDIT_VISITOR_FAILURE,
    err,
  };
}

export const UPDATE_VISITOR = 'UPDATE_VISITOR';
export function updateVisitor(data) {
  return {
    type: UPDATE_VISITOR,
    data,
  };
}

export const GET_VISITOR_REQUEST = 'GET_VISITOR_REQUEST';
function getVisitorRequest() {
  return {
    type: GET_VISITOR_REQUEST,
  };
}

export const GET_VISITOR_SUCCESS = 'GET_VISITOR_SUCCESS';
function getVisitorSuccess(data) {
  return {
    type: GET_VISITOR_SUCCESS,
    data,
  };
}

export const GET_VISITOR_FAILURE = 'GET_VISITOR_FAILURE';
function getVisitorFailure(err) {
  return {
    type: GET_VISITOR_FAILURE,
    err,
  };
}

export const GET_VISITORS_REQUEST = 'GET_VISITORS_REQUEST';
function getVisitorsRequest() {
  return {
    type: GET_VISITORS_REQUEST,
  };
}

export const GET_VISITORS_SUCCESS = 'GET_VISITORS_SUCCESS';
function getVisitorsSuccess(data, pagination, page) {
  return {
    type: GET_VISITORS_SUCCESS,
    data,
    pagination,
    page,
  };
}

export const GET_VISITORS_FAILURE = 'GET_VISITORS_FAILURE';
function getVisitorsFailure(error) {
  return {
    type: GET_VISITORS_FAILURE,
    error,
  };
}
