import {
  CLEAR_ACTIVITY_DATA,
  CLEAR_SITES,
  GET_ACTIVE_EMERGENCY_FAILURE,
  GET_ACTIVE_EMERGENCY_REQUEST,
  GET_ACTIVE_EMERGENCY_SUCCESS,
  GET_EMERGENCY_FAILURE,
  GET_EMERGENCY_REPORT_FAILURE,
  GET_EMERGENCY_REPORT_REQUEST,
  GET_EMERGENCY_REPORT_SUCCESS,
  GET_EMERGENCY_REQUEST,
  GET_EMERGENCY_SUCCESS,
  GET_MUSTER_LOCATION_FAILURE,
  GET_MUSTER_LOCATION_REQUEST,
  GET_MUSTER_LOCATION_SUCCESS,
  GET_SITES_FAILURE,
  GET_SITES_REQUEST,
  GET_SITES_SUCCESS,
  GET_SITE_ACTIVITY_FAILURE,
  GET_SITE_ACTIVITY_REQUEST,
  GET_SITE_ACTIVITY_SUCCESS,
  GET_SITE_EMERGENCY_FAILURE,
  GET_SITE_EMERGENCY_REQUEST,
  GET_SITE_EMERGENCY_SUCCESS,
  GET_SITE_FAILURE,
  GET_SITE_MUSTER_LOCATION_FAILURE,
  GET_SITE_MUSTER_LOCATION_REQUEST,
  GET_SITE_MUSTER_LOCATION_SUCCESS,
  GET_SITE_REQUEST,
  GET_SITE_SUCCESS,
  SET_SITES_TOTAL,
  UPDATE_EMERGENCY,
  UPDATE_MUSTER_LOCATION,
  UPDATE_SITE,
} from '../../actions/Sites/Sites';

const InitialState = {
  noData: false,
  busy: false,
  res: false,
  error: false,
  failure: 0,
  details: {
    name: '',
    company: {
      id: '',
      name: '',
      network: {
        id: '',
      },
    },
    contact: {
      name: '',
      email: '',
      phone: '',
    },
    config: {
      autocheckout: 0,
      timezone: '',
      features: [],
      entry: {
        iLevel: {
          curriculum: '',
        },
      },
    },
    notes: '',
    network: {
      id: '',
    },
    status: '',
  },
  activeEmergencies: [],
  emergency: {
    name: '',
    company: {
      id: '',
      name: '',
    },
    contact: {
      name: '',
      email: '',
      phone: '',
    },
    notes: '',
    network: {
      id: '',
    },
    site: {
      id: '',
      name: '',
      timezone: '',
    },
    author: {
      network_id: '',
      id: '',
      name: '',
    },
    start_date: '',
    end_date: '',
    status: '',
  },
  location: {
    company: {
      id: '',
      name: '',
    },
    name: '',
    contacts: [],
    notes: '',
    network: {
      id: '',
    },
    site: {
      id: '',
    },
  },
  report: {
    onsite: 0,
    present: 0,
    absent: 0,
    visitors: [],
  },
  data: [],
  activity: [],
  locations: [],
  emergencies: [],
  total: 0,
  activeEmergencyBusy: false,
  page: 1,
  pagination: {
    totalItems: 0,
  },
};

export default function siteService(state = InitialState, action) {
  switch (action.type) {
    case CLEAR_ACTIVITY_DATA:
      return {
        ...state,
        activity: [],
      };
    case GET_SITES_REQUEST:
      return {
        ...state,
        busy: true,
        res: false,
        data: [],
        details: InitialState.details,
      };
    case GET_SITES_SUCCESS:
      return {
        ...state,
        busy: false,
        res: true,
        data: action.data.data.sites,
        total: action.data.data.sites.length,
      };
    case GET_SITES_FAILURE:
      return {
        ...state,
        busy: false,
        res: false,
        error: action.error,
      };
    case GET_SITE_REQUEST:
      return {
        ...state,
        busy: true,
        details: InitialState.details,
      };
    case GET_SITE_SUCCESS:
      return {
        ...state,
        busy: false,
        error: false,
        res: true,
        details: {
          ...action.data.data,
          entry: action.data.data.entry,
          hasAutoCheckOutValue: action.data.data.config.autocheckout && true,
          config: {
            timezone: action.data.data.config.timezone,
            autocheckout: action.data.data.config.autocheckout,
            features: action.data.data.config.features,
          },
          company: {
            ...action.data.data.company,
            network: {
              id: action.data.network.id,
            },
          },
          network: {
            ...action.data.network,
          },
        },
      };
    case GET_SITE_FAILURE:
      return {
        ...state,
        busy: false,
        error: true,
        res: false,
      };
    case UPDATE_SITE:
      return {
        ...state,
        details: {
          ...state.details,
          ...action.data,
        },
      };
    case SET_SITES_TOTAL:
      return {
        ...state,
        total: action.data,
      };
    case CLEAR_SITES:
      return {
        ...state,
        busy: false,
        res: true,
        data: [],
        total: 0,
      };
    case GET_SITE_ACTIVITY_REQUEST:
      return {
        ...state,
        busy: true,
        res: false,
      };
    case GET_SITE_ACTIVITY_SUCCESS:
      const activity = action.data;

      let noData = state.noData;
      if (!activity.length && !state.activity.length) {
        noData = true;
      }

      return {
        ...state,
        busy: false,
        res: true,
        activity: [...state.activity, ...activity],
        offset: activity[activity.length - 1] && activity[activity.length - 1].lastEvent,
        pagination: action.pagination,
        page: action.page ? action.page : 1,
        noData: noData,
      };
    case GET_SITE_ACTIVITY_FAILURE:
      return {
        ...state,
        busy: false,
        err: true,
      };
    case GET_SITE_EMERGENCY_REQUEST:
      return {
        ...state,
        res: false,
      };
    case GET_SITE_EMERGENCY_SUCCESS:
      return {
        ...state,
        res: true,
        emergencies: action.data.data.emergencies,
      };
    case GET_SITE_EMERGENCY_FAILURE:
      return {
        ...state,
        err: true,
      };
    case GET_EMERGENCY_REQUEST:
      return {
        ...state,
      };
    case GET_EMERGENCY_SUCCESS:
      return {
        ...state,
        error: false,
        res: true,
        emergency: {
          ...action.data.data,
          network: {
            ...action.data.network,
          },
        },
      };
    case GET_EMERGENCY_FAILURE:
      return {
        ...state,
        error: true,
        res: false,
      };
    case GET_ACTIVE_EMERGENCY_REQUEST:
      return {
        ...state,
        activeEmergencyBusy: true,
      };
    case GET_ACTIVE_EMERGENCY_SUCCESS:
      return {
        ...state,
        error: false,
        res: true,
        activeEmergencies: action.data.data.emergencies,
        activeEmergencyBusy: false,
      };
    case GET_ACTIVE_EMERGENCY_FAILURE:
      return {
        ...state,
        failure: state.failure + 1,
        error: true,
        res: false,
      };
    case GET_EMERGENCY_REPORT_REQUEST:
      return {
        ...state,
      };
    case GET_EMERGENCY_REPORT_SUCCESS:
      return {
        ...state,
        error: false,
        res: true,
        report: {
          ...action.data.data,
          network: {
            ...action.data.network,
          },
        },
      };
    case GET_EMERGENCY_REPORT_FAILURE:
      return {
        ...state,
        error: true,
        res: false,
      };
    case UPDATE_EMERGENCY:
      return {
        ...state,
        emergency: {
          ...state.emergency,
          ...action.data,
        },
      };
    case GET_SITE_MUSTER_LOCATION_REQUEST:
      return {
        ...state,
        busy: true,
        res: false,
      };
    case GET_SITE_MUSTER_LOCATION_SUCCESS:
      return {
        ...state,
        busy: false,
        res: true,
        locations: action.data.data.locations,
      };
    case GET_SITE_MUSTER_LOCATION_FAILURE:
      return {
        ...state,
        busy: false,
        err: true,
      };
    case GET_MUSTER_LOCATION_REQUEST:
      return {
        ...state,
        busy: true,
      };
    case GET_MUSTER_LOCATION_SUCCESS:
      return {
        ...state,
        busy: false,
        error: false,
        res: true,
        location: {
          ...action.data.data,
          network: {
            ...action.data.network,
          },
        },
      };
    case GET_MUSTER_LOCATION_FAILURE:
      return {
        ...state,
        busy: false,
        error: true,
        res: false,
      };
    case UPDATE_MUSTER_LOCATION:
      return {
        ...state,
        location: {
          ...state.location,
          ...action.data,
        },
      };
    default:
      return state;
  }
}
