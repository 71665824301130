import MainCard from "@alliancesafetycouncil/asc-card";
import React from "react";
import { connect } from "react-redux";
import { Col, FormGroup, Label, Row } from "reactstrap";
import { Field, Form, formValueSelector, reduxForm } from "redux-form";
import GateEntryInput from "../../../components/FormContainer/GateEntryInput";
import SelectInput from "../../../components/FormContainer/SelectInput";
import TextInput from "../../../components/FormContainer/TextInput";
import { oneOf, required, validEmail, validPhone } from "../../../library/Validation";
import ASCButton from "@alliancesafetycouncil/asc-button";

let AddCompanyForm = ({ username, password, handleSubmit, pristine, busy, history }) => {
  return (
    <Form onSubmit={handleSubmit}>
      <MainCard type="main">
        <Row form>
          <Col lg={6}>
            <FormGroup>
              <Field
                id="name"
                name="name"
                component={TextInput}
                type="text"
                label="Company Name"
                placeholder=""
                validate={[required]}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col lg={6}>
            <FormGroup>
              <Field
                id="status"
                name="status"
                label="Status"
                component={SelectInput}
                validate={[oneOf(["active", "inactive"])]}
              >
                {" "}
                <option>Please Select</option>
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </Field>
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col lg={6}>
            <FormGroup>
              <Label for="status" className="primary-heading">
                Emergency/Muster List
              </Label>
              <Row form>
                <Col lg={1}>
                  <Field
                    id="hasMusterFeature"
                    name="hasMusterFeature"
                    className="form-control-input"
                    label="Emergency/Muster List"
                    component="input"
                    type="checkbox"
                  />
                </Col>
              </Row>
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col lg={6}>
            <FormGroup>
              <Label for="status" className="primary-heading">
                Block List
              </Label>
              <Row form>
                <Col lg={1}>
                  <Field
                    id="hasBlockFeature"
                    name="hasBlockFeature"
                    className="form-control-input"
                    label="Block List"
                    component="input"
                    type="checkbox"
                  />
                </Col>
              </Row>
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col lg={6}>
            <FormGroup>
              <Field
                id="contactName"
                name="contact.name"
                label="Company Contact Name"
                component={TextInput}
                type="text"
                placeholder=""
                validate={[required]}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col lg={6}>
            <FormGroup>
              <Field
                id="mobile"
                name="contact.phone"
                label="Contact Phone"
                component={TextInput}
                type="text"
                placeholder=""
                validate={[required, validPhone]}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col lg={6}>
            <FormGroup>
              <Field
                id="email"
                name="contact.email"
                label="Contact Email Address"
                component={TextInput}
                type="email"
                placeholder=""
                validate={[required, validEmail]}
              />
            </FormGroup>
          </Col>
        </Row>
        <GateEntryInput username={username} password={password} />
        <Row form>
          <Col lg={12}>
            <FormGroup>
              <Label for="exampleText" className="primary-heading">
                Notes
              </Label>
              <Field id="notes" name="notes" component="textarea" className="form-control" />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col lg={12}>
            <FormGroup>
              <div className="flex flex-row">
                <div className="mr-2">
                  <ASCButton type="save" size="md" onClick={handleSubmit} disabled={busy}>
                    Save
                  </ASCButton>
                </div>
                <div>
                  <ASCButton type="tertiary" size="md" onClick={() => history.goBack()}>
                    Cancel
                  </ASCButton>
                </div>
              </div>
            </FormGroup>
          </Col>
        </Row>
      </MainCard>
    </Form>
  );
};

AddCompanyForm = reduxForm({
  form: "addCompany",
})(AddCompanyForm);

const selector = formValueSelector("addCompany");
AddCompanyForm = connect((state) => {
  const username = selector(state, "entry.iLevel.username");
  const password = selector(state, "entry.iLevel.password");

  return {
    username,
    password,
  };
})(AddCompanyForm);

export default AddCompanyForm;
