import PageTitle from "@alliancesafetycouncil/asc-page-title";
import React, { Component } from "react";
import JotformEmbed from "react-jotform-embed";
import { Breadcrumb, BreadcrumbItem, Col, Row } from "reactstrap";

class Support extends Component {
  render() {
    return (
      <div className="add-container">
        <div className="container-heading">
          <Breadcrumb tag="nav" listTag="div">
            <BreadcrumbItem active>Support</BreadcrumbItem>
          </Breadcrumb>
          <PageTitle icon="profile">Support</PageTitle>

          <Row>
            <Col xs="12">
              <JotformEmbed src="https://form.jotformeu.com/201885744830057" />
              <script type="text/javascript" src="https://form.jotform.com/jsform/201885744830057"></script>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default Support;
