import PageTitle from '@alliancesafetycouncil/asc-page-title';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Breadcrumb, BreadcrumbItem, Toast, ToastBody } from 'reactstrap';
import { ackToast } from '../../../actions/Status/Status';
import { getUser } from '../../../actions/Users/Users';
import TabDisplay from '../../../components/TabDisplay/TabDisplay';
import DetailsTab from './DetailsTab';

class UserDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      config: [
        {
          name: 'Details',
          path: '/details',
          component: DetailsTab,
          default: true,
          exact: true,
        },
      ],
    };
  }

  componentWillMount() {
    const { id, networkId } = this.props.match.params;

    if (networkId === 'super') {
      this.props.GetUser(id);
    } else {
      this.props.GetUser(id, networkId || null);
    }
  }

  render() {
    const {
      AckToast,
      busy,
      res,
      err,
      details,
      role,
      match: {
        params: { id, networkId },
      },
    } = this.props;

    const { company, name } = details;

    const isSuccess = !busy && res;
    const isFailure = !busy && err;

    if (isSuccess || isFailure) {
      const timer = setTimeout(() => {
        AckToast();
        clearTimeout(timer);
      }, 5000);
    }

    const networkPath = networkId ? `/${networkId}` : '';

    return (
      <div className="flex flex-1 flex-col">
        <Breadcrumb tag="nav" listTag="div">
          {role !== 'siteadmin' && (
            <BreadcrumbItem
              tag="a"
              href={
                company && company.id
                  ? `/#/companies/company-details/${networkId}/${company.id}/activity-report`
                  : '/#/companies'
              }
            >
              {(company && company.name) || 'Companies'}
            </BreadcrumbItem>
          )}
          <BreadcrumbItem>
            {name.first} {name.last}
          </BreadcrumbItem>
        </Breadcrumb>
        <PageTitle icon="user" editLink={`#/users/edit-user/${id}${networkPath}`}>
          {name.first} {name.last}
        </PageTitle>
        <TabDisplay
          {...this.props}
          tabs={this.state.config}
          edit={{
            path: `#/users/edit-user/${id}${networkPath}`,
            label: 'Edit User',
          }}
        />
        <Toast onClick={AckToast} isOpen={isSuccess} className="toast success">
          <ToastBody>Success!</ToastBody>
        </Toast>
        <Toast onClick={AckToast} isOpen={isFailure} className="toast error">
          <ToastBody>
            <p>{err && err.message}</p>
          </ToastBody>
        </Toast>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    busy: state.statusService.busy,
    res: state.statusService.res,
    err: state.statusService.err,
    details: state.userService.details,
    role: state.authService.role,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetUser: (userId, networkId) => dispatch(getUser(userId, networkId)),
    AckToast: () => dispatch(ackToast()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDetails);
