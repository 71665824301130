import axios from 'axios';
import Config from '../../config/config.json';
import { setToastFailure, setToastSuccess } from '../Toast/Toast';

export function getUsers(direction, limit, offset, sort) {
  return async (dispatch) => {
    dispatch(getUsersRequest());
    return axios
      .get(`${Config.api.endpoint}/users?sortDirection=${direction}&limit=${limit}&offset=${offset}&sortColumn=${sort}`)
      .then((res) => {
        dispatch(getUsersSuccess(res.data));
      })
      .catch((err) => {
        dispatch(getUsersFailure('Could not fetch.'));
      });
  };
}

export function getUser(userId, networkId) {
  const networkPath = networkId ? `?networkId=${networkId}` : '';
  return async (dispatch) => {
    dispatch(getUserRequest);
    return axios
      .get(`${Config.api.endpoint}/users/${userId}${networkPath}`)
      .then((res) => {
        dispatch(getUserSuccess(res.data));
      })
      .catch((err) => {
        dispatch(getUserFailure(err));
      });
  };
}

export function getUsersByNetwork(networkId) {
  return async (dispatch) => {
    dispatch(getUsersRequest());
    return axios
      .get(`${Config.api.endpoint}/users?networkId=${networkId}`)
      .then((res) => {
        dispatch(getUsersSuccess(res.data));
      })
      .catch((err) => {
        dispatch(getUsersFailure('Could not fetch.'));
      });
  };
}

export function updateUserPermissions(payload, url) {
  return async (dispatch) => {
    dispatch(setPermissionsRequest());
    return axios
      .patch(`${Config.api.endpoint}/users/${url}`, { permissions: payload })
      .then((res) => {
        dispatch(updateUser({ permissions: payload }));
        dispatch(setPermissionsSuccess(res));
        dispatch(setToastSuccess());
      })
      .catch((err) => {
        dispatch(setPermissionsFailure(err));
        dispatch(setToastFailure({ err: err.response.data.data.message }));
      });
  };
}

export const SET_USERS_TOTAL = 'SET_USERS_TOTAL';
export function setUsersTotal(data) {
  return {
    type: SET_USERS_TOTAL,
    data,
  };
}

export const GET_USERS_REQUEST = 'GET_USERS_REQUEST';
function getUsersRequest() {
  return {
    type: GET_USERS_REQUEST,
  };
}

export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
function getUsersSuccess(data) {
  return {
    type: GET_USERS_SUCCESS,
    data,
  };
}

export const GET_USERS_FAILURE = 'GET_USERS_FAILURE';
function getUsersFailure(error) {
  return {
    type: GET_USERS_FAILURE,
    error,
  };
}

export const GET_USER_REQUEST = 'GET_USER_REQUEST';
function getUserRequest() {
  return {
    type: GET_USER_REQUEST,
  };
}

export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
function getUserSuccess(data) {
  return {
    type: GET_USER_SUCCESS,
    data,
  };
}

export const GET_USER_FAILURE = 'GET_USER_FAILURE';
function getUserFailure(err) {
  return {
    type: GET_USER_FAILURE,
    err,
  };
}

export const UPDATE_USER = 'UPDATE_USER';
export function updateUser(data) {
  return {
    type: UPDATE_USER,
    data,
  };
}

export const HANDLE_PERMISSIONS_REQUEST = 'HANDLE_PERMISSIONS_REQUEST';
export function handlePermissionsRequest(payload) {
  return {
    type: HANDLE_PERMISSIONS_REQUEST,
    payload,
  };
}

export const CANCEL_PERMISSIONS_REQUEST = 'CANCEL_PERMISSIONS_REQUEST';
export function cancelPermissionsRequest() {
  return {
    type: CANCEL_PERMISSIONS_REQUEST,
  };
}

export const SET_PERMISSIONS_REQUEST = 'SET_PERMISSIONS_REQUEST';
function setPermissionsRequest() {
  return {
    type: SET_PERMISSIONS_REQUEST,
  };
}

export const SET_PERMISSIONS_SUCCESS = 'SET_PERMISSIONS_SUCCESS';
function setPermissionsSuccess(data) {
  return {
    type: SET_PERMISSIONS_SUCCESS,
    data,
  };
}

export const SET_PERMISSIONS_FAILURE = 'SET_PERMISSIONS_FAILURE';
function setPermissionsFailure(err) {
  return {
    type: SET_PERMISSIONS_FAILURE,
    err,
  };
}
