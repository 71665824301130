import React from 'react';
import { NavLink } from 'react-router-dom';

export const Sidenav = ({ SignOut, Profile }) => {
  const companyId = window.user.company_id;
  const siteId = window.user.site_id;
  const networkId = window.user.network_id;
  const role = window.user.role;

  return (
    <div className="h-screen w-56 rounded-tr-xl rounded-br-xl bg-blue-900 z-40">
      {/* Large Screens */}
      <div className="hidden md:block py-6 text-center bg-blue-500 rounded-tr-xl">
        <a className="text-white text-3xl" href="/">
          <span className="font-bold">Gate</span>
          <span className="font-thin">Check</span>
        </a>
      </div>
      {/* Small Screens */}
      <div className="md:hidden py-2 text-center bg-blue-500">
        <div className="text-white text-3xl">
          <span className="font-bold">Menu</span>
        </div>
      </div>
      {/* Menu Items Wrapper */}
      <div className="flex flex-1 flex-col mt-4">
        {/* "Companies" Tab for Superadmin */}
        {role === 'superadmin' && (
          <NavLink
            activeClassName="bg-blue-800 rounded-full"
            className="flex items-center mx-3 my-10px px-4 py-3 text-white hover:no-underline"
            to="/companies"
          >
            <i className="fal fa-building text-xl text-orange-500 mr-2"></i>
            All Companies
          </NavLink>
        )}
        {/* "My Company" Tab for Owneradmin */}
        {role === 'owneradmin' && (
          <NavLink
            activeClassName="bg-blue-800 rounded-full"
            className="flex items-center mx-3 my-10px px-4 py-3 text-white hover:no-underline"
            to={'/companies/company-details/' + networkId + '/' + companyId + '/activity-report'}
          >
            <i className="fal fa-building text-xl text-orange-500 mr-2"></i>
            My Company
          </NavLink>
        )}
        {/* "My Site" Tab for Siteadmin */}
        {role === 'siteadmin' && (
          <NavLink
            activeClassName="bg-blue-800 rounded-full"
            className="flex items-center mx-3 my-10px px-4 py-3 text-white hover:no-underline"
            to={'/sites/site-details/' + siteId + '/' + networkId + '/activity-report'}
          >
            <i className="fal fa-map-marker-alt text-xl text-orange-500 mr-2"></i>
            My Site
          </NavLink>
        )}
        {/* "All SiteS" Tab for Superadmin and Owneradmin */}
        {role && ['superadmin', 'owneradmin'].includes(role) && (
          <NavLink
            activeClassName="bg-blue-800 rounded-full"
            className="flex items-center mx-3 my-10px px-4 py-3 text-white hover:no-underline"
            to="/sites"
          >
            <i className="fal fa-map-marker-alt text-xl text-orange-500 mr-2"></i>
            All Sites
          </NavLink>
        )}
        <NavLink
          activeClassName="bg-blue-800 rounded-full"
          className="flex items-center mx-3 my-10px px-4 py-3 text-white hover:no-underline"
          to="/gates"
        >
          <i className="fal fa-door-open text-xl text-orange-500 mr-2"></i>
          All Gates
        </NavLink>
        <NavLink
          activeClassName="bg-blue-800 rounded-full"
          className="flex items-center mx-3 my-10px px-4 py-3 text-white hover:no-underline"
          to="/users"
        >
          <i className="fal fa-users text-xl text-orange-500 mr-2"></i>
          All Users
        </NavLink>
        <NavLink
          activeClassName="bg-blue-800 rounded-full"
          className="flex items-center mx-3 my-10px px-4 py-3 text-white hover:no-underline"
          to="/visitors"
        >
          <i className="fal fa-door-open text-xl text-orange-500 mr-2"></i>
          All Visitors
        </NavLink>
        <NavLink
          activeClassName="bg-blue-800 rounded-full"
          className="flex items-center mx-3 my-10px px-4 py-3 text-white hover:no-underline"
          to="/profile"
        >
          <i className="fal fa-user-circle text-xl text-orange-500 mr-2"></i>
          My Profile
        </NavLink>
        <NavLink
          activeClassName="bg-blue-800 rounded-full"
          className="flex items-center mx-3 my-10px px-4 py-3 text-white hover:no-underline"
          to="/support"
        >
          <i className="fal fa-question-circle text-xl text-orange-500 mr-2"></i>
          Support
        </NavLink>
        <NavLink
          className="flex items-center mx-3 my-10px px-4 py-3 text-white hover:no-underline"
          to="#"
          onClick={() => SignOut()}
        >
          <i className="fal fa-sign-out-alt text-xl text-orange-500 mr-2"></i>
          Sign Out
        </NavLink>
      </div>
    </div>
  );
};
