import MainCard from "@alliancesafetycouncil/asc-card";
import PageTitle from "@alliancesafetycouncil/asc-page-title";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Form, FormGroup, Input, Label, Toast, ToastBody } from "reactstrap";
import {
  ForgotPasswordAck,
  ForgotPasswordFailure,
  ForgotPasswordRequest,
  ForgotPasswordSuccess,
} from "../../actions/ForgotPassword/ForgotPassword";
import Config from "../../config/config.json";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      success: false,
      error: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleToast = this.handleToast.bind(this);
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleToast() {
    this.props.ForgotPasswordAck();
  }

  handleSubmit(event) {
    const { email } = this.state;
    const { ForgotPasswordRequest, ForgotPasswordSuccess, ForgotPasswordFailure, history } = this.props;

    ForgotPasswordRequest();

    if (!email) {
      return ForgotPasswordFailure("Please enter a valid email.");
    }

    axios
      .post(`${Config.api.endpoint}/forgot`, {
        email: email,
      })
      .then((res) => {
        ForgotPasswordSuccess("Success!");
        history.push("/sign-in");
      })
      .catch((error) => {
        ForgotPasswordFailure("Please enter a valid email.");
      });

    event.preventDefault();
  }

  render() {
    const { email } = this.state;
    const { busy, error, res, msg } = this.props;

    return (
      <div className="sign-in container mt-8 md:w-1/2">
        <div className="mb-6 text-4xl text-center">
          <span className="font-bold text-black-600">Gate</span>
          <span className="font-normal text-blue-500">Check</span>
        </div>
        <Form className="sign-in form">
          <MainCard type="main">
            <FormGroup>
              <PageTitle>Forgot Password?</PageTitle>
              {/* <div>
                <h1>Forgot Password?</h1>
              </div> */}
              <br></br>
              <Label for="exampleEmail">Please enter your Email Address below to Reset Your Password.</Label>
              <br></br>
              <Input
                value={email}
                onChange={this.handleChange}
                type="email"
                name="email"
                id="email"
                placeholder="Email Address"
                className="email"
              />
              <br></br>
              <Button className="sign-in button forgot-password" onClick={this.handleSubmit}>
                Submit
              </Button>
              <Button className="sign-in button cancel" href="#/sign-in">
                Cancel
              </Button>
            </FormGroup>
          </MainCard>
        </Form>
        <Toast onClick={this.handleToast} isOpen={res && !busy} className="toast success">
          <ToastBody>
            <p>{msg}</p>
          </ToastBody>
        </Toast>
        <Toast onClick={this.handleToast} isOpen={error && !busy} className="toast error">
          <ToastBody>
            <p>{msg}</p>
          </ToastBody>
        </Toast>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    busy: state.forgotPasswordService.busy,
    error: state.forgotPasswordService.error,
    res: state.forgotPasswordService.res,
    msg: state.forgotPasswordService.msg,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ForgotPasswordRequest: () => dispatch(ForgotPasswordRequest()),
    ForgotPasswordSuccess: (res) => dispatch(ForgotPasswordSuccess(res)),
    ForgotPasswordFailure: (error) => dispatch(ForgotPasswordFailure(error)),
    ForgotPasswordAck: () => dispatch(ForgotPasswordAck()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
