import PageTitle from '@alliancesafetycouncil/asc-page-title';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { clearSites, getSitesByNetwork } from '../../../actions/Sites/Sites';
import { getUser } from '../../../actions/Users/Users';
import FormContainer from '../../../components/FormContainer/FormContainer';
import { getRoles } from '../../../library/Utilities';
import EditUserForm from './EditUserForm';

class EditUser extends Component {
  constructor(props) {
    super(props);

    const { id, networkId } = props.match.params;

    props.GetUser(id, networkId || undefined);
    props.ClearSites();

    if (networkId) {
      props.GetSitesByNetwork(networkId);
    }
  }

  render() {
    const { details, match, role, dataSite: sites } = this.props;
    const networkPath = match.params.networkId ? `/${match.params.networkId}` : '';

    return (
      <div className="flex flex-1 flex-col">
        <Breadcrumb tag="nav" listTag="div">
          <BreadcrumbItem tag="a" href="#/users" className="bread-crumb-root">
            Users
          </BreadcrumbItem>
          <BreadcrumbItem>Edit Users</BreadcrumbItem>
        </Breadcrumb>
        <PageTitle icon="user">Edit User</PageTitle>
        <FormContainer
          {...this.props}
          entity="users"
          action="edit"
          redirect={`/users/user-details/${match.params.id}${networkPath}/details`}
          Form={EditUserForm}
          handleValues={(values) => {
            const permissions = [];

            if (values.fullMuster === true) {
              permissions.push('full:muster');
            }

            return {
              notes: values.notes,
              name: values.name,
              sites: ['siteadmin', 'gateguard'].includes(values.roles) ? [values.site.toString()] : undefined,
              permissions,
              phone: values.phone,
              email: values.email,
              status: values.status,
            };
          }}
          initialValues={details}
          roles={getRoles(role)}
          getSites={this.props.GetSitesByNetwork}
          sites={sites}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companies: state.companyService.data,
    details: state.userService.details,
    role: state.authService.role,
    busySite: state.siteService.busy,
    resSite: state.siteService.res,
    errSite: state.siteService.err,
    dataSite: state.siteService.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetUser: (userId, networkId) => dispatch(getUser(userId, networkId)),
    ClearSites: () => dispatch(clearSites()),
    GetSitesByNetwork: (networkId) => dispatch(getSitesByNetwork(networkId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditUser);
