import Moment from 'moment-timezone';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getEmergencyReport } from '../../../actions/Sites/Sites';
import TableProvider from '../../../components/TableProvider/TableProvider';
import TableTotals from '../../../components/TableProvider/TableTotals';

class MusterReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columnDefs: [
        { headerName: 'Visitor', field: 'name', cellRenderer: 'renderName' },
        // { headerName: "Phone", field: "contact.phone" },
        {
          headerName: 'Marked Present',
          field: 'marked_present',
          cellRenderer: (data) => {
            return Moment(data.marked_present).format('MM/DD/YYYY');
          },
        },
        { headerName: 'Marked Present By', field: 'marked_by' },
        {
          headerName: 'Status',
          field: 'status',
          filter: 'agSetColumnFilter',
          valueFormatter: (params) => params.value.charAt(0).toUpperCase() + params.value.slice(1, params.value.length),
          filterParams: {
            newRowsAction: 'keep',
          },
        },
      ],
      options: [],
    };
  }

  render() {
    return (
      <TableProvider
        {...this.props}
        defaultFilter={false}
        totalsComponent={() => <TableTotals className="muster totals" config={this.props.totals} />}
        columnDefs={this.state.columnDefs}
        getData={() => this.props.GetEmergencyReport(this.props.match.params.id, this.props.match.params.networkId)}
        customCells={this.state.customCells}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    busy: state.siteService.busy,
    res: state.siteService.res,
    err: state.siteService.err,
    totals: [
      {
        name: 'On-Site',
        total: state.siteService.report.onsite,
        type: 'neutral',
      },
      {
        name: 'Present',
        total: state.siteService.report.present,
        type: 'positive',
      },
      {
        name: 'Absent',
        total: state.siteService.report.absent,
        type: 'negative',
      },
    ],
    data: state.siteService.report.visitors,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetEmergencyReport: (siteId, networkId) => dispatch(getEmergencyReport(siteId, networkId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MusterReport);
