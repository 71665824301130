import axios from 'axios';
import Config from '../../config/config.json';

export function getCompanyActivity(networkId, offset, paginate = false, page) {
  return async (dispatch) => {
    if (!paginate) {
      dispatch(clearActivityData());
    }

    let url = `${Config.api.endpoint}/activity?networkId=${networkId}&limit=1000`;

    if (offset) {
      url += `&after=${offset}`;
    }

    dispatch(getActivityRequest());
    await axios
      .get(url)
      .then((res) => {
        dispatch(getActivitySuccess(res.data.data.activity, res.data.data.pagination, page));
      })
      .catch((_) => {
        dispatch(getActivityFailure('Could not fetch.'));
      });
  };
}

export const CLEAR_ACTIVITY_DATA = 'CLEAR_ACTIVITY_DATA';
function clearActivityData() {
  return {
    type: CLEAR_ACTIVITY_DATA,
  };
}

export const GET_ACTIVITY_REQUEST = 'GET_ACTIVITY_REQUEST';
function getActivityRequest() {
  return {
    type: GET_ACTIVITY_REQUEST,
  };
}

export const GET_ACTIVITY_SUCCESS = 'GET_ACTIVITY_SUCCESS';
function getActivitySuccess(data, pagination, page) {
  return {
    type: GET_ACTIVITY_SUCCESS,
    data,
    pagination,
    page,
  };
}

export const GET_ACTIVITY_FAILURE = 'GET_ACTIVITY_FAILURE';
function getActivityFailure(err) {
  return {
    type: GET_ACTIVITY_FAILURE,
    err,
  };
}
