import MainCard from '@alliancesafetycouncil/asc-card';
import React from 'react';
import { connect } from 'react-redux';
import { Button, Col, Form, FormGroup, Row } from 'reactstrap';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import SelectInput from '../../../components/FormContainer/SelectInput';
import TextInput from '../../../components/FormContainer/TextInput';
import { oneOf, required, validEmail, validPhone } from '../../../library/Validation';
import ASCButton from '@alliancesafetycouncil/asc-button';

let EditUserForm = ({ history, match, handleSubmit, companies, roles, userRole, sites, busy }) => {
  return (
    <Form onSubmit={handleSubmit}>
      <MainCard type="main">
        <Row form className="user-row-one">
          <Col md={6} className="add-col user-col-one">
            <FormGroup>
              <Field
                type="text"
                name="name.first"
                id="firstName"
                label="First Name"
                component={TextInput}
                placeholder=""
                className="form-control"
                validate={[required]}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col md={6}>
            <FormGroup>
              <Field
                type="text"
                name="name.last"
                id="lastName"
                label="Last Name"
                component={TextInput}
                placeholder=""
                className="form-control"
                validate={[required]}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col md={6}>
            <FormGroup>
              <Field
                id="status"
                name="status"
                className="form-control"
                label="Status"
                validate={[oneOf(['active', 'inactive', 'unverified'])]}
                component={SelectInput}
              >
                <option>Please Select</option>
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
                <option value="unverified">Unverified</option>
              </Field>
            </FormGroup>
          </Col>
        </Row>
        <Row form className="user-row-two">
          <Col md={6} className="add-col user-col-two">
            <Field
              id="company"
              name="company.network.id"
              className="form-control"
              label="Company"
              component={SelectInput}
              disabled={true}
            >
              {companies &&
                companies.map((company) => {
                  return (
                    <option key={company.network.id} value={company.network.id}>
                      {company.name}
                    </option>
                  );
                })}
            </Field>
          </Col>
        </Row>
        <Row form>
          <Col md={6} className="add-col">
            <Field
              id="roles"
              name="roles"
              className="form-control"
              label="Role"
              component={SelectInput}
              disabled={true}
            >
              {roles &&
                roles.map((role) => {
                  return (
                    <option key={role.value} value={role.value}>
                      {role.label}
                    </option>
                  );
                })}
            </Field>
          </Col>
        </Row>
        {(userRole === 'siteadmin' || userRole === 'gateguard') && (
          <Row form>
            <Col lg={6}>
              <FormGroup>
                <Field id="site" name="site" className="form-control" label="Site" component={SelectInput}>
                  {sites &&
                    sites.map((site) => {
                      return (
                        <option key={site.id} value={site.id}>
                          {site.name}
                        </option>
                      );
                    })}
                </Field>
              </FormGroup>
            </Col>
          </Row>
        )}
        <Row form className="user-row-three">
          <Col md={6} className="add-col user-col-four">
            <FormGroup>
              <Field
                id="email"
                name="email"
                label="Contact Email Address"
                component={TextInput}
                type="email"
                placeholder=""
                validate={[required, validEmail]}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col md={6} className="add-col user-col-three">
            <FormGroup>
              <Field
                id="password"
                name="password"
                component={TextInput}
                type="password"
                label="Password"
                className="form-control"
                disabled={true}
                placeholder="**********"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col md={6} className="add-col user-col-three">
            <Button
              color="primary"
              onClick={() => history.push(`/profile/change-password/${match.params.id}/${match.params.networkId}`)}
            >
              Change Password
            </Button>{' '}
          </Col>
        </Row>
        <br />
        <Row form className="user-row-four">
          <Col md={6} className="add-col">
            <FormGroup>
              <Field
                type="text"
                name="phone"
                id="phoneNumber"
                label="Phone Number"
                placeholder=""
                component={TextInput}
                className="form-control"
                validate={[required, validPhone]}
              />
            </FormGroup>
          </Col>
        </Row>
        {userRole === 'gateguard' && (
          <Row form className="user-row-four">
            <Col md={2} className="add-col">
              <FormGroup>
                <Field
                  name="fullMuster"
                  id="fullMuster"
                  label="Muster Access"
                  placeholder=""
                  component={TextInput}
                  className="form-control-input"
                  type="checkbox"
                />
              </FormGroup>
            </Col>
          </Row>
        )}
        <Row form>
          <Col lg={12}>
            <FormGroup>
              <Field
                type="textarea"
                name="notes"
                label="Notes"
                component={TextInput}
                id="text"
                className="form-control"
                required={false}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col lg={12}>
            <FormGroup>
              <div className="flex flex-row">
                <div className="mr-2">
                  <ASCButton type="save" size="md" onClick={handleSubmit} disabled={busy}>
                    Save
                  </ASCButton>
                </div>
                <div>
                  <ASCButton type="tertiary" size="md" onClick={() => history.goBack()}>
                    Cancel
                  </ASCButton>
                </div>
              </div>
            </FormGroup>
          </Col>
        </Row>
      </MainCard>
    </Form>
  );
};

EditUserForm = reduxForm({
  form: 'editUser',
  enableReinitialize: true,
})(EditUserForm);

const selector = formValueSelector('editUser');
EditUserForm = connect((state) => {
  const userRole = selector(state, 'roles');
  return { userRole };
})(EditUserForm);

export default EditUserForm;
