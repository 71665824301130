// ag-grid styles
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
// ag-grid imports
import { AgGridReact } from 'ag-grid-react';
import Moment from 'moment-timezone';
import React, { Component } from 'react';
import styled from 'styled-components';
// cell renderers
import { CellLink } from './CellLink';
import Pagination from './Pagination';
import { RowTools } from './RowTools';

var commonWidth = 120;
var wideWidth = 140;
var dateWidth = 270;

const OverlayContainer = styled.div`
  position: absolute;
  background: white;
  display: flex;
  width: calc(100% - 12px);
  height: calc(100% - 80px);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  bottom: 7px;
  left: 6px;
  box-shadow: 0 0 5px 3px #fff;
  background: rgba(255, 255, 255, 0.75);
`;

const Overlay = styled.div`
  transform: translateY(-40px);
`;

class ActivityReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalWorkers: '',
      totalDays: '',
      totalRecords: '',
      quickFilterText: null,
      gridOptions: {
        defaultColDef: {
          resizable: true,
          sortable: true,
        },
        localeText: { noRowsToShow: 'There are no rows to display...' },
      },
      filterColumns: ['checkIn', 'checkOut'],
      suppressCellSelection: true,
      columnDefs: [
        {
          headerName: 'Visitor',
          children: [
            {
              headerName: 'Name',
              field: 'name',
              sortable: true,
              filter: true,
              width: 240,
            },
            // {
            //   headerName: "Employer",
            //   field: "visitorEmployer",
            //   sortable: true,
            //   filter: true,
            //   width: 240,
            // },
            {
              headerName: 'Compliance',
              field: 'compliance',
              sortable: true,
              filter: true,
              width: wideWidth,
              columnGroupShow: 'open',
              colId: 'overall',
              cellClass: function (params) {
                const statusValue = params.value || 'Default';
                return {
                  'non-compliant': 'bg-cell-red',
                  compliant: 'bg-cell-green',
                  Default: 'bg-cell-gray',
                }[statusValue];
              },
            },
            {
              headerName: 'ID',
              field: 'studentId',
              sortable: true,
              filter: true,
              width: 80,
              columnGroupShow: 'open',
            },
          ],
        },
        {
          headerName: 'Status',
          children: [
            {
              headerName: 'Site Status',
              field: 'siteStatus',
              sortable: true,
              filter: true,
              width: wideWidth,
              colId: 'overall',
              cellClass: function (params) {
                const statusValue = params.value || 'Default';
                return {
                  Denied: 'bg-cell-red',
                  'Checked In': 'bg-cell-green',
                  'Checked Out': 'bg-cell-gray',
                  Default: 'bg-cell-gray',
                }[statusValue];
              },
            },
            {
              headerName: 'Comments',
              field: 'notes',
              sortable: true,
              filter: true,
              columnGroupShow: 'open',
              width: commonWidth,
            },
            {
              headerName: 'Check In',
              field: 'checkIn',
              cellRenderer: (data) => {
                return data.data.checkIn ? Moment(data.data.checkIn).tz(data.data.timezone).format('llll z') : '';
              },
              sortable: true,
              columnGroupShow: 'open',
              width: dateWidth,
            },
            {
              headerName: 'Check Out',
              field: 'checkOut',
              cellRenderer: (data) => {
                return data.data.checkOut ? Moment(data.data.checkOut).tz(data.data.timezone).format('llll z') : '';
              },
              sortable: true,
              columnGroupShow: 'open',
              width: dateWidth,
            },
            {
              headerName: 'Duration',
              field: 'duration',
              sortable: true,
              filter: true,
              columnGroupShow: 'open',
              width: commonWidth,
            },
            {
              headerName: 'Gate In',
              field: 'gateIn',
              sortable: true,
              filter: true,
              columnGroupShow: 'open',
              width: commonWidth,
            },
            {
              headerName: 'Gate Out',
              field: 'gateOut',
              sortable: true,
              filter: true,
              columnGroupShow: 'open',
              width: commonWidth,
            },
            {
              headerName: 'Site',
              field: 'site',
              sortable: true,
              filter: true,
              columnGroupShow: 'open',
              width: commonWidth,
            },
          ],
        },
        {
          headerName: 'Last Event',
          field: 'lastEvent',
          cellRenderer: (data) => {
            return data.data.lastEvent ? Moment(data.data.lastEvent).tz(data.data.timezone).format('llll z') : '';
          },
          sortable: true,
          filter: true,
          columnGroupShow: 'open',
          suppressSizeToFit: true,
          flex: 1,
          minWidth: dateWidth,
        },
      ],
      rowData: [],
      frameworkComponents: {
        CellLink: CellLink,
        RowTools: RowTools,
      },
    };
  }

  onFirstDataRendered = () => {
    if (this.props.defaultFilter) {
      this.setDefaultFilter();
    }
  };

  onGridReady = async (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    this.props.setContext({
      export: () => params.api.exportDataAsExcel(),
      csv: () => params.api.exportDataAsCsv(),
    });

    this.props.getData();
  };

  handleOverlay = () => {
    // disabled overlay
    if (this.props.disabled === true) {
      return (
        <OverlayContainer>
          <Overlay style={{ fontWeight: 'bold', padding: '10px', border: '2px solid red' }}>
            This feature has been disabled. <br />
            Please contact your administrator.
          </Overlay>
        </OverlayContainer>
      );
    }

    //busy overlay
    if (this.props.busy === true) {
      return (
        <OverlayContainer>
          <Overlay className="ag-overlay-loading-center">Loading...</Overlay>
        </OverlayContainer>
      );
    }

    // no-rows overlay
    if (this.props.busy === false && this.props.res === true && this.props.noData === true) {
      return (
        <OverlayContainer>
          <Overlay className="ag-overlay-loading-center">No Rows To Show</Overlay>
        </OverlayContainer>
      );
    }
  };

  getNextPage = async (page) => {
    // dont fetch if we already have this page
    if (page > this.props.page) {
      await this.props.getData(this.props.offset, true, page);
    }

    this.gridApi.paginationGoToNextPage();
  };

  getPrevPage = () => {
    this.gridApi.paginationGoToPreviousPage();
  };

  onBtnExportDataAsExcel = () => {
    this.gridApi.exportDataAsExcel();
  };

  autoSizeAll = (skipHeader) => {
    var allColumnIds = [];
    this.gridColumnApi.getAllColumns().forEach(function (column) {
      allColumnIds.push(column.colId);
    });
    this.gridColumnApi.autoSizeColumns(allColumnIds, skipHeader);
  };

  isGreaterThanFilter = (value) => {
    let { startDate } = this.props;

    if (!value) {
      return true;
    } else {
      const date = new Date(value);
      value = date.getTime();
    }

    return value > startDate.getTime();
  };

  isLessThanFilter = (value) => {
    let { endDate } = this.props;

    if (!value) {
      return true;
    } else {
      const date = new Date(value);
      value = date.getTime();
    }

    return value < endDate.getTime();
  };

  inRangeFilter = (value) => {
    let { startDate, endDate } = this.props;

    if (!value) {
      return true;
    } else {
      const date = new Date(value);
      value = date.getTime();
    }

    return value > startDate.getTime() && value < endDate.getTime();
  };

  isExternalFilterPresent = () => {
    const { startDate, endDate } = this.props;

    return Boolean(startDate || endDate);
  };

  handleFilterChanged = () => {
    this.gridApi.onFilterChanged();
  };

  doesExternalFilterPass = (node) => {
    const { startDate, endDate } = this.props;

    delete node.data.network;
    const { checkIn, checkOut } = node.data;

    if (!endDate) {
      return this.isGreaterThanFilter(checkIn) && this.isGreaterThanFilter(checkOut);
    }

    if (!startDate) {
      return this.isLessThanFilter(checkIn) && this.isLessThanFilter(checkOut);
    }

    return this.inRangeFilter(checkIn) && this.inRangeFilter(checkOut);
  };

  componentDidUpdate(prevProps) {
    const { startDate, endDate } = this.props;

    if (startDate !== prevProps.startDate || endDate !== prevProps.endDate) {
      this.handleFilterChanged();
    }
  }

  // Relative Time Implementation
  getRelativeTime(timestamp) {
    return Moment(timestamp).add(Moment().utcOffset(), 'minutes').fromNow();
  }

  render() {
    const { data, pagination, busy } = this.props;

    return (
      <div
        className="ag-grid-wrap activity-report"
        style={{
          flex: '1',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <div
          className="ag-theme-balham"
          style={{
            flex: '1',
            width: '100%',
            position: 'relative',
          }}
        >
          <AgGridReact
            gridOptions={this.state.gridOptions}
            quickFilterText={this.props.filter}
            columnDefs={this.state.columnDefs}
            frameworkComponents={this.state.frameworkComponents}
            rowData={data}
            onGridReady={this.onGridReady}
            isExternalFilterPresent={this.isExternalFilterPresent}
            doesExternalFilterPass={this.doesExternalFilterPass}
            pagination={true}
            suppressPaginationPanel={true}
            paginationPageSize={1000}
            onFirstDataRendered={this.onFirstDataRendered}
            suppressLoadingOverlay={true}
            suppressNoRowsOverlay={true}
          />
          {this.handleOverlay()}
        </div>
        <Pagination
          busy={busy}
          getPrevPage={this.getPrevPage}
          getNextPage={(page) => this.getNextPage(page)}
          pageSize={1000}
          totalItems={pagination.totalItems}
        />
      </div>
    );
  }
}

export default ActivityReport;
