import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getUsersByNetwork } from "../../../actions/Users/Users";
import TableProvider from "../../../components/TableProvider/TableProvider";

class UsersTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columnDefs: [
        { headerName: "Name", field: "name", cellRenderer: "renderName" },
        { headerName: "Company", field: "company" },
        { headerName: "Roles", field: "roles" },
        { headerName: "Login", field: "login" },
        { headerName: "Notes", field: "notes" },
        {
          headerName: "Status",
          field: "status",
          filter: "agSetColumnFilter",
          valueFormatter: (params) => {
            const value = params.value;

            if (typeof value === "string") {
              return value.charAt(0).toUpperCase() + params.value.slice(1, params.value.length);
            }

            return value;
          },
        },
      ],
      customCells: {
        renderName: (row) => {
          return (
            row.data && (
              <Link to={`/users/user-details/${row.data.id}/${props.details.network.id}/details`}>{row.data.name}</Link>
            )
          );
        },
      },
      options: [],
    };
  }

  render() {
    const networkId = this.props.details.network.id;

    return (
      <TableProvider
        {...this.props}
        columnDefs={this.state.columnDefs}
        getData={() => networkId && this.props.GetUsersByNetwork(networkId)}
        customCells={this.state.customCells}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    busy: state.userService.busy,
    res: state.userService.res,
    err: state.userService.err,
    data: state.userService.data,
    details: state.companyService.details,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetUsersByNetwork: (networkId) => dispatch(getUsersByNetwork(networkId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersTab);
