import PageTitle from "@alliancesafetycouncil/asc-page-title";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Breadcrumb, BreadcrumbItem, Toast, ToastBody } from "reactstrap";
import { getProfile } from "../../actions/Profile/Profile";
import Config from "../../config/config.json";
import EditProfileForm from "./EditProfileForm";

class EditProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: false,
      success: false,
      message: null,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.ackToast = this.ackToast.bind(this);
  }

  handleSubmit(values) {
    const { history } = this.props;

    axios
      .patch(`${Config.api.endpoint}/users/self`, {
        name: values.name,
        phone: values.phone,
      })
      .then((res) => {
        this.handleToast(true, res);
        history.push("/profile");
      })
      .catch((error) => {
        this.handleToast(false, error.message);
      });
  }

  handleToast(success, message) {
    if (success) {
      this.setState({
        success: true,
        error: false,
      });
    } else {
      this.setState({
        success: false,
        error: true,
        message: message,
      });
    }
  }

  ackToast() {
    this.setState({
      error: false,
      success: false,
      message: null,
    });
  }

  componentWillMount() {
    const { GetProfile } = this.props;

    GetProfile();
  }

  render() {
    const { profile } = this.props;

    return (
      <div className="flex flex-1 flex-col">
        <Breadcrumb tag="nav" listTag="div">
          <BreadcrumbItem tag="a">My Profile</BreadcrumbItem>
          <BreadcrumbItem tag="a" active>
            Edit
          </BreadcrumbItem>
        </Breadcrumb>
        <PageTitle icon="profile">Edit My Profile</PageTitle>
        <EditProfileForm onSubmit={this.handleSubmit} initialValues={profile} />
        <Toast onClick={this.ackToast} isOpen={this.state.success} className="toast success">
          <ToastBody>Success!</ToastBody>
        </Toast>
        <Toast onClick={this.ackToast} isOpen={this.state.error} className="toast error">
          <ToastBody>
            <p>{this.state.message}</p>
          </ToastBody>
        </Toast>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    busy: state.profileService.busy,
    res: state.profileService.res,
    err: state.profileService.err,
    profile: state.profileService.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetProfile: () => dispatch(getProfile()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
