import Moment from "moment-timezone";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Col, FormGroup, Row } from "reactstrap";
import { cancelStatusRequest, handleStatusRequest, setStatus } from "../../../actions/Status/Status";
import { getTimezonesNames } from "../../../library/Utilities";

Moment.fn.zoneName = function () {
  var abbr = this.zoneAbbr();
  var label = getTimezonesNames()[abbr];
  return label ? `${abbr} (${label})` : abbr;
};

class DetailsTab extends Component {
  handleDeactivate = (e) => {
    e.preventDefault();

    const modalConfig = {
      show: true,
      button: "Yes, deactivate",
      body: `Are you sure you want to deactivate`,
      name: ` ${this.props.details.name}?`,
      action: () =>
        this.props.SetStatus(
          "sites",
          false,
          `${this.props.match.params.id}?networkId=${this.props.match.params.networkId}`
        ),
      cancel: () => this.props.CancelStatusRequest(),
    };

    this.props.HandleStatusRequest(modalConfig);
  };

  handleActivate = (e) => {
    e.preventDefault();

    const modalConfig = {
      show: true,
      button: "Yes, activate",
      body: `Are you sure you want to activate`,
      name: ` ${this.props.details.name}?`,
      action: () =>
        this.props.SetStatus(
          "sites",
          true,
          `${this.props.match.params.id}?networkId=${this.props.match.params.networkId}`
        ),
      cancel: () => this.props.CancelStatusRequest(),
    };

    this.props.HandleStatusRequest(modalConfig);
  };

  render() {
    const {
      details: { contact, status, notes, config },
    } = this.props;

    return (
      <Card>
        <Row form>
          <Col md={3}>
            <div>
              <strong>Contact:</strong>
              <br />
              <div>
                {contact.name}
                <br />
              </div>
              <div>
                <a href={`tel:${contact.phone}`}>{contact.phone}</a>
                <br />
              </div>
              <a href={`mailto:${contact.email}`}>{contact.email}</a>
            </div>
          </Col>
          <Col md={3}>
            <div>
              <strong>Auto Check-Out:</strong>
              <br />
              {config && config.autocheckout ? `${config.autocheckout} hours after check-in` : "Off"}
            </div>
            <br />
            <div>
              <strong>Has Emergency/Muster Access:</strong>
              <br />
              {config && config.features && config.features.includes("muster") ? "Yes" : "No"}
            </div>
            <br />
            <div>
              <strong>Has Block Access:</strong>
              <br />
              {config && config.features && config.features.includes("block") ? "Yes" : "No"}
            </div>
          </Col>
          <Col md={3}>
            <div>
              <strong>Time Zone:</strong>
              <br />
              {config && config.timezone && (
                <div>
                  {Moment().tz(config.timezone).format("zz")}
                  <br />
                  Current Time: {Moment().tz(config.timezone).format("LT")}
                </div>
              )}
            </div>
          </Col>
          <Col md={3}>
            <FormGroup>
              <div className="primary-heading">
                <strong>Status:</strong>
              </div>
              {status && (
                <div className={`details status ${status}`}>
                  {status}{" "}
                  {status === "active" ? (
                    <a
                      href="#/"
                      onClick={(e) => {
                        this.handleDeactivate(e);
                      }}
                    >
                      (Deactivate)
                    </a>
                  ) : (
                    <a
                      href="#/"
                      onClick={(e) => {
                        this.handleActivate(e);
                      }}
                    >
                      (Activate)
                    </a>
                  )}{" "}
                </div>
              )}
            </FormGroup>
          </Col>
        </Row>
        <br></br>
        <p>
          <strong>Notes</strong>
          <br />
          {notes}
        </p>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    details: state.siteService.details,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    HandleStatusRequest: (modalConfig) => dispatch(handleStatusRequest(modalConfig)),
    CancelStatusRequest: () => dispatch(cancelStatusRequest()),
    SetStatus: (entity, status, id) => dispatch(setStatus(entity, status, id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailsTab);
