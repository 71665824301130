import PageTitle from "@alliancesafetycouncil/asc-page-title";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { getCompany } from "../../../actions/Companies/Companies";
import FormContainer from "../../../components/FormContainer/FormContainer";
import EditCompanyForm from "./EditCompanyForm";

class EditCompany extends Component {
  componentWillMount() {
    const { id } = this.props.match.params;
    this.props.GetCompany(id);
  }

  render() {
    const { data, profile, match } = this.props;
    const { id, networkId } = match.params;

    return (
      <div className="flex flex-1 flex-col">
        <Breadcrumb tag="nav" listTag="div">
          <BreadcrumbItem tag="a" href="#/companies" className="bread-crumb-root">
            Companies
          </BreadcrumbItem>
          <BreadcrumbItem tag="a">Edit Company</BreadcrumbItem>
        </Breadcrumb>
        <PageTitle icon="company">Edit Company</PageTitle>
        <FormContainer
          {...this.props}
          entity="companies"
          action="edit"
          redirect={`/companies/company-details/${networkId}/${id}/details`}
          Form={EditCompanyForm}
          handleValues={(values) => {
            let features = [];
            if (values.hasMusterFeature) {
              features.push("muster");
            }
            if (values.hasBlockFeature) {
              features.push("block");
            }

            return {
              name: values.name,
              contact: values.contact,
              status: values.status,
              notes: values.notes,
              entry: values.entry,
              config: {
                features,
              },
            };
          }}
          initialValues={data}
          role={profile.role}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state.companyService.details,
    profile: state.authService,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetCompany: (id) => dispatch(getCompany(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditCompany);
