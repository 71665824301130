import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getCompany } from '../../../actions/Companies/Companies';
import { getSiteMusterLocations } from '../../../actions/Sites/Sites';
import TableProvider from '../../../components/TableProvider/TableProvider';

class MusterLocations extends Component {
  constructor(props) {
    super(props);

    this.state = {
      params: {
        direction: 'asc',
        limit: 100,
        offset: 0,
        sort: 'id',
      },
      columnDefs: [
        { headerName: 'Muster Name', field: 'name', cellRenderer: 'renderName' },
        { headerName: 'Contact', field: 'contact' },
        { headerName: 'Phone', field: 'phone' },
        { headerName: 'Notes', field: 'notes' },
        {
          headerName: 'Status',
          field: 'status',
          filter: 'agSetColumnFilter',
          valueFormatter: (params) => params.value.charAt(0).toUpperCase() + params.value.slice(1, params.value.length),
          filterParams: {
            newRowsAction: 'keep',
          },
        },
      ],
      customCells: {
        renderName: (row) => {
          return (
            row.data && (
              <Link to={`/muster-location/location-details/${row.data.id}/${props.match.params.networkId}/details`}>
                {row.data.name}
              </Link>
            )
          );
        },
      },
      options: [],
    };
  }

  getSiteMusterLocations(cb) {
    const { direction, limit, offset, sort } = this.state.params;

    this.props.GetSiteMusterLocations(
      this.props.match.params.networkId,
      direction,
      limit,
      offset,
      sort,
      this.props.match.params.id,
      cb
    );
  }

  isDisabled = () => {
    return this.props.companyDetails.hasMusterFeature !== null
      ? !this.props.companyDetails.hasMusterFeature
      : this.props.companyDetails.hasMusterFeature;
  };

  componentDidUpdate(prevProps) {
    if (this.props.siteDetails.company.id && this.props.siteDetails.company.id !== prevProps.siteDetails.company.id) {
      this.props.GetCompany(this.props.siteDetails.company.id);
    }
  }

  render() {
    const { direction, limit, offset, sort } = this.state.params;

    return (
      <TableProvider
        {...this.props}
        disabled={this.isDisabled()}
        columnDefs={this.state.columnDefs}
        getData={() =>
          this.props.GetSiteMusterLocations(
            this.props.match.params.networkId,
            direction,
            limit,
            offset,
            sort,
            this.props.match.params.id
          )
        }
        customCells={this.state.customCells}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    busy: state.siteService.busy,
    res: state.siteService.res,
    err: state.siteService.err,
    data: state.siteService.locations,
    companyDetails: state.companyService.details,
    siteDetails: state.siteService.details,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetCompany: (id) => dispatch(getCompany(id)),
    GetSiteMusterLocations: (networkdId, direction, limit, offset, sort, id) =>
      dispatch(getSiteMusterLocations(networkdId, direction, limit, offset, sort, id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MusterLocations);
