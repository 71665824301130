import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getSitesByNetwork } from "../../../actions/Sites/Sites";
import TableProvider from "../../../components/TableProvider/TableProvider";

class SitesTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columnDefs: [
        { headerName: "Site", field: "name", cellRenderer: "renderName" },
        { headerName: "Contact", field: "contact" },
        { headerName: "Email", field: "email" },
        { headerName: "Phone", field: "phone" },
        {
          headerName: "Status",
          field: "status",
          filter: "agSetColumnFilter",
          valueFormatter: (params) =>
            params.value && params.value.charAt(0).toUpperCase() + params.value.slice(1, params.value.length),
        },
      ],
      customCells: {
        renderName: (props) => {
          if (props.data.networkId) {
            const networkId = props.data.networkId.split("");
            networkId.splice(2, 0, "-");
            return (
              props.data && (
                <Link to={`/sites/site-details/${props.data.id}/${networkId.join("")}/activity-report`}>
                  {props.data.name}
                </Link>
              )
            );
          }

          if (props.data.network) {
            const networkId = props.data.network.id.split("");
            networkId.splice(2, 0, "-");
            return (
              props.data && (
                <Link to={`/sites/site-details/${props.data.id}/${networkId.join("")}/activity-report`}>
                  {props.data.name}
                </Link>
              )
            );
          }

          return "";
        },
      },
      options: [],
    };
  }

  render() {
    const networkId = this.props.details.network.id;

    return (
      <TableProvider
        {...this.props}
        columnDefs={this.state.columnDefs}
        getData={() => networkId && this.props.GetSitesByNetwork(networkId)}
        customCells={this.state.customCells}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    busy: state.siteService.busy,
    res: state.siteService.res,
    err: state.siteService.err,
    data: state.siteService.data,
    details: state.companyService.details,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetSitesByNetwork: (networkId) => dispatch(getSitesByNetwork(networkId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SitesTab);
