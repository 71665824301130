import MainCard from '@alliancesafetycouncil/asc-card';
import PageTitle from '@alliancesafetycouncil/asc-page-title';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { getCompanies, setCompaniesTotal } from '../../../actions/Companies/Companies';
import TableProvider from '../../../components/TableProvider/TableProvider';

class Companies extends Component {
  constructor(props) {
    super(props);

    this.state = {
      params: {
        direction: 'asc',
        limit: 100,
        offset: 0,
        sort: 'name',
      },
      columnDefs: [
        { headerName: 'Company', field: 'name', cellRenderer: 'renderName' },
        { headerName: 'Contact', field: 'contact' },
        { headerName: 'Email', field: 'email', cellRenderer: 'renderEmail' },
        { headerName: 'Phone', field: 'phone' },
        {
          headerName: 'Status',
          field: 'status',
          filter: 'agSetColumnFilter',
          valueFormatter: (params) => params.value.charAt(0).toUpperCase() + params.value.slice(1, params.value.length),
          filterParams: {
            newRowsAction: 'keep',
          },
        },
      ],
      defaultColDef: {
        sortable: true,
        flex: 1,
        minWidth: 100,
        filter: true,
        comparator: (a, b) => a.toLowerCase().localeCompare(b.toLowerCase()),
      },
      customCells: {
        renderName: (row) =>
          row.data && (
            <Link to={`/companies/company-details/${row.data.network.id}/${row.data.id}/activity-report`}>
              {row.data.name}
            </Link>
          ),
        renderEmail: (row) => row.data && <a href={`mailto:${row.data.email}`}>{row.data.email}</a>,
      },
      config: {
        add: {
          path: '#/companies/add-company',
          label: 'Add Company',
        },
      },
    };
  }

  render() {
    const { direction, limit, offset, sort } = this.state.params;

    return (
      <Fragment>
        <Breadcrumb tag="nav" listTag="div">
          <BreadcrumbItem active>All Companies</BreadcrumbItem>
        </Breadcrumb>
        <PageTitle icon="company">All Companies</PageTitle>
        <MainCard type="main">
          <div className="ag-theme-balham flex flex-1 flex-col">
            <TableProvider
              {...this.props}
              columnDefs={this.state.columnDefs}
              getData={() => this.props.GetCompanies(direction, limit, offset, sort)}
              config={this.state.config}
              customCells={this.state.customCells}
              defaultColDef={this.state.defaultColDef}
            />
          </div>
        </MainCard>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    busy: state.companyService.busy,
    res: state.companyService.res,
    err: state.companyService.err,
    data: state.companyService.data,
    details: state.companyService.details,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetCompanies: (direction, limit, offset, sort) => dispatch(getCompanies(direction, limit, offset, sort)),
    SetCompaniesTotal: (count) => dispatch(setCompaniesTotal(count)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Companies);
