import MainCard from '@alliancesafetycouncil/asc-card';
import PageTitle from '@alliancesafetycouncil/asc-page-title';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { getGates } from '../../../actions/Gates/Gates';
import TableProvider from '../../../components/TableProvider/TableProvider';

class Gates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      params: {
        direction: 'asc',
        limit: 100,
        offset: 0,
        sort: 'id',
      },
      columnDefs: [
        { headerName: 'Gate', field: 'name', cellRenderer: 'renderName' },
        { headerName: 'Site', field: 'site' },
        { headerName: 'Company', field: 'company' },
        { headerName: 'Contact', field: 'contact' },
        { headerName: 'Phone', field: 'phone' },
        { headerName: 'Notes', field: 'notes' },

        {
          headerName: 'Status',
          field: 'status',
          filter: 'agSetColumnFilter',
          valueFormatter: (params) => params.value.charAt(0).toUpperCase() + params.value.slice(1, params.value.length),
          filterParams: {
            newRowsAction: 'keep',
          },
        },
      ],
      defaultColDef: {
        sortable: true,
        flex: 1,
        minWidth: 100,
        filter: true,
      },
      customCells: {
        renderName: (row) => {
          const networkId = row.data.network ? `/${row.data.network.id}` : '';
          return <Link to={`/gates/gate-details/${row.data.id}${networkId}/details`}>{row.data.name}</Link>;
        },
      },
      config: {
        add: {
          path: '#/gates/add-gate',
          label: 'Add Gate',
        },
      },
    };
  }

  getGates = () => {
    const { direction, limit, offset, sort } = this.state.params;

    this.props.GetGates(direction, limit, offset, sort);
  };

  render() {
    const { direction, limit, offset, sort } = this.state.params;

    return (
      <Fragment>
        <Breadcrumb tag="nav" listTag="div">
          <BreadcrumbItem active> All Gates</BreadcrumbItem>
        </Breadcrumb>
        <PageTitle icon="gate">All Gates</PageTitle>
        <MainCard type="main">
          <div className="ag-theme-balham flex flex-1 flex-col">
            <TableProvider
              {...this.props}
              columnDefs={this.state.columnDefs}
              getData={() => this.props.GetGates(direction, limit, offset, sort)}
              config={this.state.config}
              customCells={this.state.customCells}
              defaultColDef={this.state.defaultColDef}
            />
          </div>
        </MainCard>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    busy: state.gateService.busy,
    res: state.gateService.res,
    err: state.gateService.err,
    data: state.gateService.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetGates: (direction, limit, offset, sort) => dispatch(getGates(direction, limit, offset, sort)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Gates);
