import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getGatesByNetwork } from "../../../actions/Gates/Gates";
import TableProvider from "../../../components/TableProvider/TableProvider";

class GatesTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columnDefs: [
        { headerName: "Gate Name", field: "name", cellRenderer: "renderName" },
        { headerName: "Parent Site", field: "site" },
        { headerName: "Parent Company", field: "company" },
        { headerName: "Contact Name", field: "contact" },
        { headerName: "Contact Phone", field: "phone" },
        { headerName: "Notes", field: "notes" },
        {
          headerName: "Status",
          field: "status",
          filter: "agSetColumnFilter",
          valueFormatter: (params) => params.value.charAt(0).toUpperCase() + params.value.slice(1, params.value.length),
        },
      ],
      customCells: {
        renderName: (row) => {
          return (
            row.data && (
              <Link to={`/gates/gate-details/${row.data.id}/${props.details.network.id}/details`}>{row.data.name}</Link>
            )
          );
        },
      },
      options: [],
    };
  }

  render() {
    const networkId = this.props.details.network.id;

    return (
      <TableProvider
        {...this.props}
        columnDefs={this.state.columnDefs}
        getData={() => networkId && this.props.GetGatesByNetwork(networkId)}
        customCells={this.state.customCells}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    busy: state.gateService.busy,
    res: state.gateService.res,
    err: state.gateService.err,
    data: state.gateService.data,
    details: state.companyService.details,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetGatesByNetwork: (networkId) => dispatch(getGatesByNetwork(networkId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GatesTab);
