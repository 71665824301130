import { Component } from "react";
import config from "../config/config";

const defaultTimeout = config.sso.timeout;
export class IdleTimeout extends Component {
  currentTimeout;
  timeout = defaultTimeout * 1000 * 60;

  setTimeout = () => {
    if (this.currentTimeout) this.clearTimeout();

    this.currentTimeout = setTimeout(() => {
      this.props.action();
    }, this.timeout);
  };

  clearTimeout = () => {
    clearTimeout(this.currentTimeout);
  };

  componentDidMount() {
    window.addEventListener("mousemove", this.setTimeout);
  }

  componentWillUnmount() {
    this.clearTimeout();
  }

  render() {
    this.setTimeout();

    return null;
  }
}
