import ASCButton from '@alliancesafetycouncil/asc-button';
import MainCard from '@alliancesafetycouncil/asc-card';
import React from 'react';
import { Col, Form, FormGroup, Label, Row } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import SelectInput from '../../../components/FormContainer/SelectInput';
import TextInput from '../../../components/FormContainer/TextInput';
import { required, validEmail, validPhone } from '../../../library/Validation';

let EditGateForm = ({ history, handleSubmit, companies, initialValues, curriculums, networkBusy }) => {
  return (
    <Form onSubmit={handleSubmit}>
      <MainCard type="main">
        <Row form>
          <Col lg={6}>
            <FormGroup>
              <Field
                type="text"
                name="name"
                id="name"
                label="Gate Name"
                placeholder=""
                component={TextInput}
                className="form-control"
                validate={[required]}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col lg={6}>
            <FormGroup>
              <Field
                id="company"
                name="company.network.id"
                component={SelectInput}
                className="form-control"
                label="Company"
                validate={[required]}
                disabled={true}
              >
                <option value={''}>Loading...</option>
                {companies &&
                  companies.map((company) => {
                    return (
                      <option key={company.network.id} value={company.network.id}>
                        {company.name}
                      </option>
                    );
                  })}
              </Field>
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col lg={6}>
            <FormGroup>
              <Field
                name="site.id"
                id="site"
                placeholder=""
                label="Site"
                component={SelectInput}
                validate={required}
                className="form-control"
                disabled={true}
              >
                <option value={''}>Loading...</option>
                {
                  <option key={initialValues.site.id} value={initialValues.site.id}>
                    {initialValues.site.name}
                  </option>
                }
              </Field>
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col lg={6}>
            <FormGroup>
              <Field
                type="text"
                name="contact.name"
                id="gateContactName"
                label="Gate Contact (Name)"
                placeholder=""
                component={TextInput}
                className="form-control"
                required={false}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col lg={6}>
            <FormGroup>
              <Field
                type="text"
                name="contact.phone"
                id="contactPhone"
                label="Contact Phone"
                placeholder=""
                component={TextInput}
                className="form-control"
                validate={[validPhone]}
                required={false}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col lg={6}>
            <FormGroup>
              <Field
                type="email"
                name="contact.email"
                component={TextInput}
                label="Contact Email Address"
                id="email"
                placeholder=""
                className="form-control"
                validate={[validEmail]}
                required={false}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col lg={6}>
            <FormGroup>
              <Field
                id="ilevelCurriculum"
                name="config.entry.iLevel.curriculum"
                label="iLevel Curriculum"
                component={SelectInput}
                className="form-control"
                validate={[required]}
                disabled={networkBusy}
              >
                <option disabled={networkBusy} value={0}>
                  {networkBusy ? 'Loading...' : 'Same as Site'}
                </option>
                {curriculums.map((curriculum, i) => {
                  return (
                    <option key={`curriculum-${i}`} value={curriculum.id}>
                      {curriculum.id} - {curriculum.name}
                    </option>
                  );
                })}
              </Field>
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col lg={12}>
            <FormGroup>
              <Label for="exampleText" className="primary-heading">
                Notes
              </Label>
              <Field id="notes" name="notes" component="textarea" className="form-control" />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col lg={12}>
            <FormGroup>
              <div className="flex flex-row">
                <div className="mr-2">
                  <ASCButton type="save" size="md" onClick={handleSubmit}>
                    Save
                  </ASCButton>
                </div>
                <div>
                  <ASCButton type="tertiary" size="md" onClick={() => history.goBack()}>
                    Cancel
                  </ASCButton>
                </div>
              </div>
            </FormGroup>
          </Col>
        </Row>
      </MainCard>
    </Form>
  );
};

EditGateForm = reduxForm({
  form: 'editGate',
  enableReinitialize: true,
})(EditGateForm);

export default EditGateForm;
