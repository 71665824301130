import PageTitle from "@alliancesafetycouncil/asc-page-title";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Breadcrumb, BreadcrumbItem, Toast, ToastBody } from "reactstrap";
import { getProfile } from "../../actions/Profile/Profile";
import Config from "../../config/config.json";
import ChangePasswordForm from "./ChangePasswordForm";

class ChangePassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userId: this.props.match.params.id,
      networkId: this.props.match.params.networkId || null,
      error: false,
      success: false,
      message: null,
      showPassword: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.ackToast = this.ackToast.bind(this);
  }

  handlePasswordVisibility = (e) => {
    e.preventDefault();

    this.setState({
      showPassword: !this.state.showPassword,
    });
  };

  handleSubmit(values) {
    const { history } = this.props;
    const { userId, networkId } = this.state;

    let networkPath = "";
    if (networkId) {
      networkPath = `?networkId=${networkId}`;
    }

    const url = userId ? `${Config.api.endpoint}/users/${userId}${networkPath}` : `${Config.api.endpoint}/users/self`;

    axios
      .patch(url, {
        password: values.password,
      })
      .then((res) => {
        this.handleToast(true, res);

        if (userId) {
          history.push(`/users/user-details/${userId}${networkId ? `/${networkId}` : ""}/details`);
        } else {
          history.push("/profile");
        }
      })
      .catch((error) => {
        this.handleToast(false, error.response.data.data.message);
      });
  }

  handleToast(success, message) {
    if (success) {
      this.setState({
        success: true,
        error: false,
      });
    } else {
      this.setState({
        success: false,
        error: true,
        message: message,
      });
    }
  }

  ackToast() {
    this.setState({
      error: false,
      success: false,
      message: null,
    });
  }

  componentWillMount() {
    const { GetProfile } = this.props;

    GetProfile(this.state.userId, this.state.networkId);
  }

  render() {
    const { profile } = this.props;
    const { showPassword } = this.state;

    return (
      <div className="flex flex-1 flex-col">
        <Breadcrumb tag="nav" listTag="div">
          <BreadcrumbItem tag="a">
            {this.state.userId ? `${profile.name.first} ${profile.name.last}` : "My Profile"}
          </BreadcrumbItem>
          <BreadcrumbItem tag="a" active>
            Change Password
          </BreadcrumbItem>
        </Breadcrumb>
        <PageTitle icon="profile">Change Password</PageTitle>
        <ChangePasswordForm
          onSubmit={this.handleSubmit}
          initialValues={profile}
          handlePasswordVisibility={this.handlePasswordVisibility}
          showPassword={showPassword}
        />
        <Toast onClick={this.ackToast} isOpen={this.state.success} className="toast success">
          <ToastBody>Success!</ToastBody>
        </Toast>
        <Toast onClick={this.ackToast} isOpen={this.state.error} className="toast error">
          <ToastBody>
            <p>{this.state.message}</p>
          </ToastBody>
        </Toast>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    busy: state.profileService.busy,
    res: state.profileService.res,
    err: state.profileService.err,
    profile: state.profileService.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetProfile: (userId, networkId) => dispatch(getProfile(userId, networkId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
