import axios from 'axios';
import Config from '../../config/config.json';

export function getSites(direction, limit, offset, sort) {
  return async (dispatch) => {
    dispatch(getSitesRequest());
    return axios
      .get(`${Config.api.endpoint}/sites?sortDirection=${direction}&limit=${limit}&offset=${offset}&sortColumn=${sort}`)
      .then((res) => {
        dispatch(getSitesSuccess(res.data));
      })
      .catch((err) => {
        dispatch(getSitesFailure('Could not fetch.'));
      });
  };
}

export function getSite(networkId, id) {
  return async (dispatch) => {
    dispatch(getSiteRequest());
    return axios
      .get(`${Config.api.endpoint}/sites/${id}?networkId=${networkId}`)
      .then((res) => {
        dispatch(getSiteSuccess(res.data));
      })
      .catch((err) => {
        dispatch(getSiteFailure(err));
      });
  };
}

export function getSitesByNetwork(networkId) {
  return async (dispatch) => {
    dispatch(getSitesRequest());
    return axios
      .get(`${Config.api.endpoint}/sites?networkId=${networkId}`)
      .then((res) => {
        dispatch(getSitesSuccess(res.data));
      })
      .catch((err) => {
        dispatch(getSitesFailure('Could not fetch.'));
      });
  };
}

export function getSiteActivity(networkId, siteId, offset, paginate = false, page) {
  return async (dispatch) => {
    if (!paginate) {
      dispatch(clearActivityData());
    }

    let url = `${Config.api.endpoint}/activity?networkId=${networkId}&siteId=${siteId}&limit=1000`;

    if (offset) {
      url += `&after=${offset}`;
    }

    dispatch(getActivityRequest());
    return axios
      .get(url)
      .then((res) => {
        dispatch(getActivitySuccess(res.data.data.activity, res.data.data.pagination, page));
      })
      .catch((err) => {
        dispatch(getActivityFailure('Could not fetch.'));
      });
  };
}

export function getSiteEmergencies(id, networkId, direction, limit, offset, sort) {
  return async (dispatch) => {
    dispatch(getSiteEmergencyRequest());
    return axios
      .get(
        `${Config.api.endpoint}/emergencies?siteId=${id}&networkId=${networkId}&sortDirection=${direction}&limit=${limit}&offset=${offset}&sortColumn=${sort}`
      )
      .then((res) => {
        dispatch(getSiteEmergencySuccess(res.data));
      })
      .catch((err) => {
        dispatch(getSiteEmergencyFailure('Could not fetch.'));
      });
  };
}

export function getActiveEmergencies() {
  return async (dispatch) => {
    dispatch(getActiveEmergenciesRequest());
    return axios
      .get(`${Config.api.endpoint}/emergencies?status=active`)
      .then((res) => {
        dispatch(getActiveEmergenciesSuccess(res.data));
      })
      .catch((err) => {
        dispatch(getActiveEmergenciesFailure('Could not fetch.'));
      });
  };
}

export function getEmergency(id, networkId) {
  return async (dispatch) => {
    dispatch(getEmergencyRequest);
    return axios
      .get(`${Config.api.endpoint}/emergencies/${id}?networkId=${networkId}`)
      .then((res) => {
        dispatch(getEmergencySuccess(res.data));
      })
      .catch((err) => {
        dispatch(getEmergencyFailure(err));
      });
  };
}

export function getEmergencyReport(id, networkId) {
  return async (dispatch) => {
    dispatch(getEmergencyReportRequest);
    return axios
      .get(`${Config.api.endpoint}/emergencies/${id}/report?networkId=${networkId}`)
      .then((res) => {
        dispatch(getEmergencyReportSuccess(res.data));
      })
      .catch((err) => {
        dispatch(getEmergencyReportFailure(err));
      });
  };
}

export function getSiteMusterLocations(networkId, direction, limit, offset, sort, id) {
  return async (dispatch) => {
    dispatch(getSiteMusterLocationRequest());
    return axios
      .get(
        `${Config.api.endpoint}/locations?networkId=${networkId}&siteId=${id}&sortDirection=${direction}&limit=${limit}&offset=${offset}&sortColumn=${sort}`
      )
      .then((res) => {
        dispatch(getSiteMusterLocationSuccess(res.data));
      })
      .catch((err) => {
        dispatch(getSiteMusterLocationFailure('Could not fetch.'));
      });
  };
}

export function getMusterLocation(id, networkId) {
  return async (dispatch) => {
    dispatch(getMusterLocationRequest);
    return axios
      .get(`${Config.api.endpoint}/locations/${id}?networkId=${networkId}`)
      .then((res) => {
        dispatch(getMusterLocationSuccess(res.data));
      })
      .catch((err) => {
        dispatch(getMusterLocationFailure(err));
      });
  };
}

export const CLEAR_ACTIVITY_DATA = 'CLEAR_ACTIVITY_DATA';
function clearActivityData() {
  return {
    type: CLEAR_ACTIVITY_DATA,
  };
}

export const GET_MUSTER_LOCATION_REQUEST = 'GET_MUSTER_LOCATION_REQUEST';
function getMusterLocationRequest() {
  return {
    type: GET_MUSTER_LOCATION_REQUEST,
  };
}

export const GET_MUSTER_LOCATION_SUCCESS = 'GET_MUSTER_LOCATION_SUCCESS';
function getMusterLocationSuccess(data) {
  return {
    type: GET_MUSTER_LOCATION_SUCCESS,
    data,
  };
}

export const GET_MUSTER_LOCATION_FAILURE = 'GET_MUSTER_LOCATION_FAILURE';
function getMusterLocationFailure(err) {
  return {
    type: GET_MUSTER_LOCATION_FAILURE,
    err,
  };
}

export const UPDATE_MUSTER_LOCATION = 'UPDATE_MUSTER_LOCATION';
export function updateMusterLocation(data) {
  return {
    type: UPDATE_MUSTER_LOCATION,
    data,
  };
}

export const GET_SITE_MUSTER_LOCATION_REQUEST = 'GET_SITE_MUSTER_LOCATION_REQUEST';
function getSiteMusterLocationRequest() {
  return {
    type: GET_SITE_MUSTER_LOCATION_REQUEST,
  };
}

export const GET_SITE_MUSTER_LOCATION_SUCCESS = 'GET_SITE_MUSTER_LOCATION_SUCCESS';
function getSiteMusterLocationSuccess(data) {
  return {
    type: GET_SITE_MUSTER_LOCATION_SUCCESS,
    data,
  };
}

export const GET_SITE_MUSTER_LOCATION_FAILURE = 'GET_SITE_MUSTER_LOCATION_FAILURE';
function getSiteMusterLocationFailure(err) {
  return {
    type: GET_SITE_MUSTER_LOCATION_FAILURE,
    err,
  };
}

export const GET_EMERGENCY_REQUEST = 'GET_EMERGENCY_REQUEST';
function getEmergencyRequest() {
  return {
    type: GET_EMERGENCY_REQUEST,
  };
}

export const GET_EMERGENCY_SUCCESS = 'GET_EMERGENCY_SUCCESS';
function getEmergencySuccess(data) {
  return {
    type: GET_EMERGENCY_SUCCESS,
    data,
  };
}

export const GET_EMERGENCY_FAILURE = 'GET_EMERGENCY_FAILURE';
function getEmergencyFailure(err) {
  return {
    type: GET_EMERGENCY_FAILURE,
    err,
  };
}

export const GET_ACTIVE_EMERGENCY_REQUEST = 'GET_ACTIVE_EMERGENCY_REQUEST';
function getActiveEmergenciesRequest() {
  return {
    type: GET_ACTIVE_EMERGENCY_REQUEST,
  };
}

export const GET_ACTIVE_EMERGENCY_SUCCESS = 'GET_ACTIVE_EMERGENCY_SUCCESS';
function getActiveEmergenciesSuccess(data) {
  return {
    type: GET_ACTIVE_EMERGENCY_SUCCESS,
    data,
  };
}

export const GET_ACTIVE_EMERGENCY_FAILURE = 'GET_ACTIVE_EMERGENCY_FAILURE';
function getActiveEmergenciesFailure(err) {
  return {
    type: GET_ACTIVE_EMERGENCY_FAILURE,
    err,
  };
}

export const GET_EMERGENCY_REPORT_REQUEST = 'GET_EMERGENCY_REPORT_REQUEST';
function getEmergencyReportRequest() {
  return {
    type: GET_EMERGENCY_REPORT_REQUEST,
  };
}

export const GET_EMERGENCY_REPORT_SUCCESS = 'GET_EMERGENCY_REPORT_SUCCESS';
function getEmergencyReportSuccess(data) {
  return {
    type: GET_EMERGENCY_REPORT_SUCCESS,
    data,
  };
}

export const GET_EMERGENCY_REPORT_FAILURE = 'GET_EMERGENCY_REPORT_FAILURE';
function getEmergencyReportFailure(err) {
  return {
    type: GET_EMERGENCY_REPORT_FAILURE,
    err,
  };
}

export const UPDATE_EMERGENCY = 'UPDATE_EMERGENCY';
export function updateEmergency(data) {
  return {
    type: UPDATE_EMERGENCY,
    data,
  };
}

export const GET_SITE_EMERGENCY_REQUEST = 'GET_SITE_EMERGENCY_REQUEST';
function getSiteEmergencyRequest() {
  return {
    type: GET_SITE_EMERGENCY_REQUEST,
  };
}

export const GET_SITE_EMERGENCY_SUCCESS = 'GET_SITE_EMERGENCY_SUCCESS';
function getSiteEmergencySuccess(data) {
  return {
    type: GET_SITE_EMERGENCY_SUCCESS,
    data,
  };
}

export const GET_SITE_EMERGENCY_FAILURE = 'GET_SITE_EMERGENCY_FAILURE';
function getSiteEmergencyFailure(err) {
  return {
    type: GET_SITE_EMERGENCY_FAILURE,
    err,
  };
}

export const GET_SITE_ACTIVITY_REQUEST = 'GET_SITE_ACTIVITY_REQUEST';
function getActivityRequest() {
  return {
    type: GET_SITE_ACTIVITY_REQUEST,
  };
}

export const GET_SITE_ACTIVITY_SUCCESS = 'GET_SITE_ACTIVITY_SUCCESS';
function getActivitySuccess(data, pagination, page) {
  return {
    type: GET_SITE_ACTIVITY_SUCCESS,
    data,
    pagination,
    page,
  };
}

export const GET_SITE_ACTIVITY_FAILURE = 'GET_SITE_ACTIVITY_FAILURE';
function getActivityFailure(err) {
  return {
    type: GET_SITE_ACTIVITY_FAILURE,
    err,
  };
}

export const SET_SITES_TOTAL = 'SET_SITES_TOTAL';
export function setSitesTotal(data) {
  return {
    type: SET_SITES_TOTAL,
    data,
  };
}

export const GET_SITES_REQUEST = 'GET_SITES_REQUEST';
function getSitesRequest() {
  return {
    type: GET_SITES_REQUEST,
  };
}

export const GET_SITES_SUCCESS = 'GET_SITES_SUCCESS';
function getSitesSuccess(data) {
  return {
    type: GET_SITES_SUCCESS,
    data,
  };
}

export const GET_SITES_FAILURE = 'GET_SITES_FAILURE';
function getSitesFailure(error) {
  return {
    type: GET_SITES_FAILURE,
    error,
  };
}

export const GET_SITE_REQUEST = 'GET_SITE_REQUEST';
function getSiteRequest() {
  return {
    type: GET_SITE_REQUEST,
  };
}

export const GET_SITE_SUCCESS = 'GET_SITE_SUCCESS';
function getSiteSuccess(data) {
  return {
    type: GET_SITE_SUCCESS,
    data,
  };
}

export const GET_SITE_FAILURE = 'GET_SITE_FAILURE';
function getSiteFailure(err) {
  return {
    type: GET_SITE_FAILURE,
    err,
  };
}

export const UPDATE_SITE = 'UPDATE_SITE';
export function updateSite(data) {
  return {
    type: UPDATE_SITE,
    data,
  };
}

export const CLEAR_SITES = 'CLEAR_SITES';
export function clearSites() {
  return {
    type: CLEAR_SITES,
  };
}
